/*
 * @Author: hanson 1130845163@qq.com
 * @Date: 2022-04-12 11:33:12
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-06-08 17:23:48
 * @FilePath: \2002181_OTLRWS_FRONTED_refactoring\src\http\analysisApi.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
// 缺陷分析页面接口
import axios from "./ajax.js"; // 导入ajax

/**
 * @Author KR0288
 * @Date 2022/05/18 15:01:33
 * @Description 分页查询缺陷分析任务
 */
export const selectErrandWithPage = data => {
  return axios({
    url: `/eprdms/errand/get`,
    method: "post",
    data
  });
};


/**
 * @Author KR0288
 * @Date 2022/05/18 09:35:46
 * @Description 更新缺陷分析任务名称
 */
export const getupdateErrand = data => {
  return axios({
    url: `/eprdms/errand/update`,
    method: "post",
    data
  });
};


/**
 * @Author KR0288
 * @Date 2022/05/18 09:37:33
 * @Description 新增缺陷分析任务
 */
export const getaddErrand = data => {
  return axios({
    url: `/eprdms/errand/add`,
    method: "post",
    data
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 09:39:29
 * @Description 删除缺陷分析任务
 */
export const getdeleteErrand = params => {
  return axios({
    url: `/eprdms/errand/delete`,
    method: "get",
    params
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 09:44:42
 * @Description 下载检测报告
 */
export const getExportReport = params => {
  return axios({
    url: `/eprdms/v2/loadFile/download/report`,
    method: "get",
    params
  });
};

// eprdms/errand/selectErrandsByName
export const getSelectErrandsByName = params => {
  return axios({
    url: `/eprdms/errand/selectErrandsByName`,
    method: "get",
    params
  });
};


/**
 * @Author KR0288
 * @Date 2022/10/17 13:24:04
 * @Description 查询加密密钥
 */
export const getSecretApi = data => {
  return axios({
    url: `/eprdms/photo/get/secret`,
    method: "post",
    data
  });
}
