<template>
  <div class="weather-top">
    <el-carousel class="weather-carousel" height="3vh" direction="vertical" :autoplay="true" indicator-position="none" :interval="5000" :popper-append-to-body="false">
        <el-tooltip class="item" effect="dark" placement="top">
          <div class="weather-info">
            <div>
              <span>{{ cityWeatherData.city }}</span>
            </div>
            <div>
              <i class="iconfont icon-wendu weather-icon"  />
              {{ cityWeatherData.temperature}}
              <!-- <el-divider direction="vertical"></el-divider> -->
            </div>
            <div>
              <i class="iconfont icon-shidu weather-icon"   />
              {{ cityWeatherData.humidity}}
              <!-- <el-divider direction="vertical"></el-divider> -->
            </div>
            <div>
              <i class="iconfont icon-fengli weather-icon"  />
              {{ cityWeatherData.windSpeed}}
              <!-- <el-divider direction="vertical"></el-divider> -->
            </div>
            <div>
              <i class="iconfont icon-fengxiang weather-icon"  />
              {{ cityWeatherData.windDirection}}
              <!-- <el-divider direction="vertical"></el-divider> -->
            </div>
            <div>
              <i class="iconfont weather-icon" :class="dynamicWeatherIcon(cityWeatherData.type)"   />
              {{cityWeatherData.type}}
            </div>
          </div>

          <div slot="content">
            <span>{{ cityWeatherData.city }}</span>
            <ul>
              <li>温度： {{ cityWeatherData.temperature }} </li>
              <li>相对湿度： {{ cityWeatherData.humidity}}</li>
              <li>风力等级： {{ cityWeatherData.windSpeed}} </li>
              <li>风向： {{ cityWeatherData.windDirection}}</li>
              <li>天气类型： {{ cityWeatherData.type}} </li>
            </ul>
          </div>
        </el-tooltip>
    </el-carousel>
  </div>
</template>
<script>
import { mapState } from "vuex";
import * as websocket from "@/utils/websocket/appWebsocket.js"
export default {
  name: "weatherFullView",

  data() {
    return {
      show: true
    };
  },
  computed: {
    ...mapState(["cityWeatherData", "city", "weatherStationList"]),
  
  },
  watch: {
    /*
     * @Date 2022/03/23 14:07:15
     * 监听当前定位城市变化，重新订阅城市气象
     */
    city: function() {
      if(this.city) {
        let data = {
          city: this.city,
          temperature: "--",
          humidity: "--",
          windSpeed: "--",
          windDirection: "--",
          type: "--"
        };
        this.$store.commit("updateCityWeather", data);
        websocket.handleChangeCity();
      }
    },

    /*
     * @Date 2022/03/23 13:38:42
     * 监听当前线路变化，重新订阅气象基站
     */
    weatherStationList: function(val) {
      websocket.handleChangeWeatherStations();
    },
    
  },
  methods: {
    dynamicWeatherIcon(type) {
      if(type.indexOf("云")>-1 || type.indexOf("阴")>-1) {
        return "icon-duoyun";
      }else if(type.indexOf("雷")>-1) {
        return "icon-leizhenyu";
      }else if(type.indexOf("雨")>-1) {
        return "icon-jiangyu";
      }else if(type.indexOf("晴")>-1) {
        return "icon-guangzhao";
      }else if(type.indexOf("雾")>-1) {
        return "icon-wu";
      }else if(type.indexOf("雪")>-1) {
        return "icon-xue";
      }else {
        return "icon-duoyun";
      }
    }
  }
};
</script>

<style scoped lang="scss">
.weather-top {
  width: 100%;
  max-height: 3vh;
  overflow-y: auto;
  /deep/ .weather-carousel {
    .item {
      display: flex; 
      flex-direction: row; 
      justify-content: space-evenly; 
      align-items: center;
      width: 100%;
      height: 100%;
      font-size: .0469rem;
      .weather-icon {
        font-size: 12px !important;
      }
    }
  }
}
</style>
