
const CryptoJS = require("crypto-js"); //引用AES源码js

/**
 * @Author KR0132
 * @Date 2022/08/24 10:11:18
 * @Description 加密数据
 * 
 */
export  function encrypt(rawData, secret, encryptIv) {
  let key = CryptoJS.enc.Utf8.parse(secret); //十六位十六进制数作为密钥
  let iv = CryptoJS.enc.Utf8.parse(encryptIv); //十六位十六进制数作为密钥偏移量
  let srcs = CryptoJS.enc.Utf8.parse(rawData);
  let encrypted = CryptoJS.AES.encrypt(srcs, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  });
  return CryptoJS.enc.Base64.stringify(encrypted.ciphertext);
}


/**
 * @Author KR0132
 * @Date 2022/08/24 10:11:29
 * @Description 解密数据
 */
export  function decrypt(encryptData, secret, encryptIv) {
  
  let key = CryptoJS.enc.Utf8.parse(secret); //十六位十六进制数作为密钥
  let iv = CryptoJS.enc.Utf8.parse(encryptIv); //十六位十六进制数作为密钥偏移量

  let encryptDataHexStr = CryptoJS.enc.Hex.parse(encryptData);
  let encryptDataBase64Str = CryptoJS.enc.Base64.stringify(encryptDataHexStr);


  let rawData = CryptoJS.AES.decrypt(encryptDataBase64Str, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  });

  return rawData.toString(CryptoJS.enc.Utf8);
}

