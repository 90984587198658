import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login.vue";
import store from '../store/index'
import { el } from '../main'

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/login",
    meta: {
      keepAlive: false,
    },
  },
  {
    path: "/login",
    name: "login",
    meta: {
      keepAlive: false,
    },
    component: Login
  },
  {
    path: '/404',
    name: '404',
    meta: {
      keepAlive: false,
    },
    component: () => import(/* webpackChunkName: "home" */ "../views/common/404.vue"),
  },
  // {
  //   path: "/alert",
  //   name: 'alert',    
  //   meta: {
  //     keepAlive: false,
  //     isBack: false,
  //   },
  //   component: () => import(/* webpackChunkName: "alert" */ "../views/alert/alert.vue")
  // },
  // {
  //   path: '/defect',
  //   name: 'defect',
  //   meta: {
  //     keepAlive: false,
  //     isBack: false,
  //   },
  //   component: () => import(/* webpackChunkName: "defect" */ "../views/alert/defect.vue")
  // }
  // {
  //   path: "/home",
  //   name: "home",
  //   meta: {
  //     keepAlive: true,
  //     isBack: false,
  //   },
  //   // route level code-splitting
  //   // this generates a separate chunk (home.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "home" */ "../views/home/home.vue")
  // },
  // {
  //   path: "/analysis",
  //   name: "analysis",
  //   meta: {
  //     keepAlive: true,
  //     isBack: false,
  //   },
  //   component: () => import(/* webpackChunkName: "analysis" */ "../views/analysis/analysis.vue")
  // },
  // {
  //   path: "/analysisDetails",
  //   name: "analysisDetails",
  //   meta: {
  //     keepAlive: false,
  //     isBack: false,
  //   },
  //   component: () => import(/* webpackChunkName: "analysisDetails" */ "../views/analysisDetails/analysisDetails.vue")
  // },
  // {
  //   path: "/inspectionPlan",
  //   name: "inspectionPlan",
  //   meta: {
  //     keepAlive: true,
  //     isBack: false,
  //   },
  //   component: () => import(/* webpackChunkName: "inspectionPlan" */ "../views/inspectionPlan/inspectionPlan.vue")
  // },
  // {
  //   path: "/query",
  //   name: "query",
  //   meta: {
  //     keepAlive: true,
  //     isBack: false,
  //   },
  //   component: () => import(/* webpackChunkName: "query" */ "../views/query/query.vue")
  //   // component: () => import(/* webpackChunkName: "query" */ "../components/imgDraw.vue")

  // },
  // {
  //   path: "/equipment",
  //   name: "equipment",
  //   meta: {
  //     keepAlive: true,
  //     isBack: false,
  //   },
  //   component: () => import(/* webpackChunkName: "equipment" */ "../views/equipment/equipment.vue")
  // },
  // {
  //   path: "/equipment-menu",
  //   name: "equipmentMenu",
  //   meta: {
  //     keepAlive: true,
  //     isBack: false,
  //   },
  //   component: () => import(/* webpackChunkName: "equipmentMenu" */ "../views/equipment/equipmentMenu.vue")
  // },
  // {
  //   path: "/photo-display",
  //   name: "photoDisplay",
  //   meta: {
  //     keepAlive: true,
  //     isBack: false,
  //   },
  //   component: () => import(/* webpackChunkName: "photoDisplay" */ "../views/display/photoDisplay.vue")
  // },
  // {
  //   path: "/simpleControl",
  //   name: "simpleControl",
  //   meta: {
  //     keepAlive: true,
  //     // keepAlive: false,
  //     isBack: false,
  //   },
  //   component: () => import(/* webpackChunkName: "simpleControl" */ "../views/simpleControl/simpleControl.vue")
  // },
  // {
  //   path: "/professionalControl",
  //   name: "professionalControl",
  //   meta: {
  //     keepAlive: true,
  //     // keepAlive: false,
  //     isBack: false,
  //   },
  //   component: () => import(/* webpackChunkName: "professionalControl" */ "../views/professionalControl/professionalControl.vue")
  // },
  // {
  //   path: "/towerListDetails",
  //   name: "towerListDetails",
  //   meta: {
  //     keepAlive: false,
  //     isBack: false,
  //   },
  //   component: () => import(/* webpackChunkName: "towerListDetails" */ "../views/towerListDetails/towerListDetails.vue")
  // },
  // {
  //   path: "/sensorconfig",
  //   name: "sensorconfig",
  //   meta: {
  //     keepAlive: true,
  //     isBack: false,
  //   },
  //   component: () =>
  //     import( /* webpackChunkName: "sensorconfig" */ "../views/sensorConfig/sensorConfig.vue")
  // },
];




const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err);
};

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});



/*
 * 导航守卫
 * @Date 2021/10/28 16:34:21
 * 路由初始化前，将客户端ID存到vuex
 */
router.beforeEach(async (to, from, next) => {
  try {

    // 1、不需要token的路由
    if (
      to.path === "/login" ||
      to.path === "/alert" ||
      to.path === "/defect"
    ) {
      if (to.path === "/alert") {
        next();

      } else if (to.path === '/defect') {
        if (from.path === '/alert') {
          next();
        } else {
          next({ path: '/alert' })
        }

      } else {
        store.commit("saveRoutesData", []);
        store.commit("updateMenuRouterLoaded", false);
        next();
      }

      // 2、需要token的路由
    } else {
      console.log(from.path, to.path);
      if (!sessionStorage.getItem("Authorization")) {
        store.commit("saveRoutesData", []);
        store.commit("updateMenuRouterLoaded", false);
        next({ path: "/login" });

      } else {
        if (!store.state.menuRouterLoaded && from.path === '/') {
          store.dispatch("addRoutes").then(res => {
            store.commit('getTabsRoutesList');

            if (!(store.state.tabsRoutesList.some(item => {
              return item.path === to.path
            }))) {

              if (store.state.tabsRoutesList.length !== 0) {
                next(store.state.tabsRoutesList[0].path)
              }
            }
          }).catch(err => {
            el.$message.error("刷新路由失败")
          })
        }
      }
    }

  } catch (err) {
    store.commit("updateMenuRouterLoaded", false);
    console.log(err);

  } finally {
    next();
  }
});
export default router;
