// 主界面接口
import axios from "./ajax.js"; // 导入ajax

/**
 * @Author KR0288
 * @Date 2022/05/18 09:01:28
 * @Description 查询所有线路的名称集合
 */
export const getLineNames = data => {
  return axios({
    url: `/eprdms/line/get/simple`,
    method: "post",
    data
  });
};


/**
 * @Author KR0288
 * @Date 2022/05/18 09:03:40
 * @Description 获取杆塔GPS
 */
export const getLineGps = data => {
  return axios({
    url: `/eprdms/tower/get/gps`,
    method: "post",
    data
  });
};

// eprdms/tower/selectTowers
// TODO://
export const getselectTowers = params => {
  return axios({
    url: `/eprdms/tower/get`,
    method: "get",
    params
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 11:52:44
 * @Description 查询指定机器最近一次巡检的图片
 */
export const getinspectImages = params => {
  return axios({
    url: `/eprdms/sftp/get/inspect/images`,
    method: "get",
    params
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 11:56:21
 * @Description 查询指定线路的缺陷图片信息
 */
export const getqueryPhotoByDef = params => {
  return axios({
    url: `/eprdms/photo/get/contain/defect`,
    method: "get",
    params
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 11:57:18
 * @Description 根据线路id查询当天巡检计划
 */
export const getPlansByLineId = params => {
  return axios({
    url: `/eprdms/plan/get/by/line`,
    method: "get",
    params
  });
};


// // 获取当前用户供电局子供电局，作为新增线路下拉选框数据
// export const getAllNextBureaus = data => {
//   return axios({
//     url: `/eprdms/admin/bureau/getAllNextBureaus`,
//     method: "get",
//     data
//   });
// };

/**
 * @Author KR0288
 * @Date 2022/05/18 13:14:32
 * @Description 添加线路时回路类型下拉框
 */
export const getCircuitTypeList = data => {
  return axios({
    url: `/eprdms/dict/circuit`,
    method: "get",
    data
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 13:15:42
 * @Description 添加线路时线路类型下拉框
 */
export const getWireTypeList = data => {
  return axios({
    url: `/eprdms/dict/wireType`,
    method: "get",
    data
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 13:16:16
 * @Description 添加线路
 */
export const addLine = data => {
  return axios({
    url: `/eprdms/line/add`,
    method: "post",
    data
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 13:16:25
 * @Description 删除线路，同步删除杆塔、计划、充电点、计划模板、photo图片、缺陷任务
 */
export const deleteLine = data => {
  return axios({
    url: `/eprdms/line/delete`,
    method: "post",
    data
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 13:17:07
 * @Description 修改线路
 */
export const updateLine = data => {
  return axios({
    url: `/eprdms/line/update`,
    method: "post",
    data
  });
};

// // 获取供电局下所有子供电局线路
// export const getLineList = data => {
//   return axios({
//     url: `/eprdms/line/getLineList`,
//     method: "get",
//     data
//   });
// };

/**
 * @Author KR0288
 * @Date 2022/05/18 13:18:40
 * @Description 根据线路id获取线路详细信息
 */
export const getLineById = params => {
  return axios({
    url: `/eprdms/line/get/id`,
    method: "get",
    params
  });
};

// 切换线路
// export const changeLine = params => {
//   return axios({
//     url: `/eprdms/line/change`,
//     method: "get",
//     params
//   });
// };

//  获取线路下所有塔杆，用于地图显示
// export const getTowersGps = params => {
//   return axios({
//     url: `/eprdms/tower/getTowersGps`,
//     method: "get",
//     params
//   });
// };


/**
 * @Author KR0288
 * @Date 2022/05/18 13:24:46
 * @Description 查询当前用户信息
 */
export const getUserInfo = params => {
  return axios({
    url: "/eprdms/user/get",
    method: "get",
    params: params
  });
}

/**
 * @Author KR0288
 * @Date 2022/05/18 13:27:09
 * @Description 获取上传的文件
 */
export const getAllFiles = params => {
  return axios({
    url: "/eprdms/document/getAllFiles",
    method: "get",
    params: params
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 13:27:17
 * @Description 删除文档
 */
export const deleteFile = data => {
  return axios({
    url: "/eprdms/document/deleteFile",
    method: "post",
    data: data
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 13:27:29
 * @Description 下载文档
 */
export const downloadFile = params => {
  return axios({
    url: "/eprdms/document/downloadFile",
    method: "get",
    params: params
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 13:29:10
 * @Description 更新用户信息
 */
export const updateUserInfo = params => {
  return axios({
    url: "/eprdms/user/update",
    method: "post",
    data: params
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 13:29:36
 * @Description 重置用户密码
 */
export const resetUserPassword = params => {
  return axios({
    url: "/eprdms/user/reset/password",
    method: "post",
    data: params
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 13:32:34
 * @Description 查询公司列表
 */
export const getBureauList = params => {
  return axios({
    url: "/eprdms/bureau/get",
    method: "get",
    params: params
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 13:32:56
 * @Description 查询公司树
 */
export const getBureauTree = params => {
  return axios({
    url: "/eprdms/bureau/get/tree",
    method: "get",
    params: params
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 13:33:14
 * @Description 切换公司
 */
export const switchBureau = params => {
  return axios({
    url: "/eprdms/bureau/switch",
    method: "get",
    params: params
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 13:34:12
 * @Description 获取当前公司
 */
export const currentBureau = params => {
  return axios({
    url: "/eprdms/bureau/current",
    method: "get",
    params: params
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 13:34:29
 * @Description 查询公司对应的行政地区
 */
export const getBureauRegion = params => {
  return axios({
    url: "/eprdms/bureau/get/region",
    method: "get",
    params: params
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 13:35:08
 * @Description 查询省
 */
export const getProvinces = params => {
  return axios({
    url: "/eprdms/region/get/province",
    method: "get",
    params: params
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 13:35:14
 * @Description 查询省下面的地级市
 */
export const getCities = params => {
  return axios({
    url: "/eprdms/region/get/city",
    method: "get",
    params: params
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 13:35:37
 * @Description 查询市下面的区或县
 */
export const getCounties = params => {
  return axios({
    url: "/eprdms/region/get/county",
    method: "get",
    params: params
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 13:36:50
 * @Description 查询资料汇编系统中的线路
 */
export const getLineFromDataSystem = params => {
  return axios({
    url: "/eprdms/line/synchronize/remote/get",
    method: "get",
    params: params
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 13:37:34
 * @Description 同步资料汇编系统的线路到本地
 */
export const SyncLineFromDataSystem = params => {
  return axios({
    url: "/eprdms/line/synchronize/remote/update",
    method: "get",
    params: params
  });
};

/**
 * @Author KR0132
 * @Date 2022/02/25 18:28:37
 * @Description 根据区域查询公司
 */
export const getBureasByRegion = params => {
  return axios({
    url: "/eprdms/bureau/get/by/region",
    method: "get",
    params: params
  });
};

/**
 * @Author KR0132
 * @Date 2022/03/11 15:10:41
 * @Description 更新用户气象推送
 */
export const updateWeatherMessageConfig = data => {
  return axios({
    url: "/eprdms/device/weather/update/push/city",
    method: "post",
    data
  });
};

/**
 * @Author KR0132
 * @Date 2022/03/28 09:37:21
 * @Description 查询检测任务统计数据
 */
export const getErrandStatics = params => {
  return axios({
    url: "/eprdms/errand/get/statics",
    method: "get",
    params
  });
}

/**
 * @Author KR0132
 * @Date 2022/03/28 09:37:21
 * @Description 查询巡检任务统计数据
 */
export const getPlanStatics = params => {
  return axios({
    url: "/eprdms/plan/get/statics",
    method: "get",
    params
  });
}

/**
 * @Author KR0132
 * @Date 2022/03/28 09:37:21
 * @Description 查询统计时间范围
 */
export const getStaticsTime = params => {
  return axios({
    url: "/eprdms/dict/statics/time",
    method: "get",
    params
  });
}

