// 专业控制界面接口
import axios from "./ajax.js"; // 导入ajax


/**
 * @Author KR0288
 * @Date 2022/05/18 14:27:39
 * @Description 查询在线机器人
 */
export const getOnlineRobot = params => {
  return axios({
    url: "/eprdms/control/robot/online",
    method: "get",
    params
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 14:29:00
 * @Description 查询所有机器人-ip是用户管理的ip
 */
export const getAllRobot = data => {
  return axios({
    url: "/eprdms/device/robot/get",
    method: "post",
    data
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 14:29:47
 * @Description 开关视频服务
 */
export const openVideoServer = data => {
  return axios({
    url: "/eprdms/control/camera/control",
    method: "post",
    data
  });
};

/**
 * @description: 分布式机器人开启视频推流
 * @date 2023-07-21 10:44
 */
export const openDistributedVideoServerApi = data => {
  return axios({
    url: "/eprdms/control/camera/pull",
    method: "post",
    data
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 14:30:32
 * @Description 充电
 */
export const robotCharge = params => {
  return axios({
    url: "/eprdms/control/robot/charge",
    method: "patch",
    params
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 14:31:28
 * @Description 登录机器人
 */
export const loginRobot = (params, robotMac) => {
  return axios({
    url: "/eprdms/control/robot/login",
    method: "put",
    params,
    headers: {
      robotMac: robotMac
    }
  });
};

/**
 * @description: 登出机器人
 * @date 2023-09-05 10:22
 */
export const logoutRobot = (params, robotToken) => {
  return axios({
    url: "/eprdms/control/robot/logout",
    method: "get",
    params,
    headers: {
      Authorization: robotToken
    }
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 14:31:51
 * @Description 重启机器人
 */
export const reboot = params => {
  return axios({
    url: "/eprdms/control/robot/reboot",
    method: "put",
    params
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 14:32:51
 * @Description 机器人前进后退
 */
export const robotWalk = data => {
  return axios({
    url: "/eprdms/control/robot/walk",
    method: "put",
    data
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 14:33:02
 * @Description 检超声波前进后退
 */
export const robotUltrwalk = params => {
  return axios({
    url: "/eprdms/control/robot/ultrwalk",
    method: "put",
    params
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 14:33:24
 * @Description 机器人停止
 */
export const robotBrake = data => {
  return axios({
    url: "/eprdms/control/robot/brake",
    method: "put",
    data
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 14:34:07
 * @Description 可见光变倍 0-25000
 */
export const aszoom = params => {
  return axios({
    url: "/eprdms/control/common/aszoom",
    method: "put",
    params
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 14:34:32
 * @Description 可见光自动对焦
 */
export const asfocus = params => {
  return axios({
    url: "/eprdms/control/common/asfocus",
    method: "put",
    params
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 14:35:01
 * @Description 红外自动对焦
 */
export const autofocus = params => {
  return axios({
    url: "/eprdms/control/common/autofocus",
    method: "put",
    params
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 14:35:23
 * @Description 请求红外-可见光拍照
 */
export const snap = params => {
  return axios({
    url: "/eprdms/control/common/snap",
    method: "put",
    params
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 14:36:31
 * @Description 管理模式所有开关接口robot/switch
 */
export const robotSwitch = params => {
  return axios({
    url: "/eprdms/control/robot/switch",
    method: "get",
    params
  });
};

/**
 * @description: 分布式机器人的睡眠唤醒
 * @date 2023-07-20 10:28
 */
export const robotSleepWaveUp = params => {
  return axios({
    url: "/eprdms/control/robot/sleep",
    method: "get",
    params
  });
}

/**
 * @Author KR0288
 * @Date 2022/05/18 14:36:51
 * @Description 重启路由
 */
export const rebootRoute = params => {
  return axios({
    url: "/eprdms/control/robot/rebootroute",
    method: "put",
    params
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 14:37:21
 * @Description 查询在线状态/传感器状态
 */
export const robotQuery = params => {
  return axios({
    url: "/eprdms/control/robot/query",
    method: "get",
    params
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 14:38:05
 * @Description 机器人自动运行 开始
 */
export const robotAutorun = data => {
  return axios({
    url: "/eprdms/control/robot/autorun",
    method: "post",
    data
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 14:40:02
 * @Description 清除-继续自动运行
 */
export const robotClearOrContinueAutorun = params => {
  return axios({
    url: "/eprdms/control/robot/auto",
    method: "put",
    params
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 14:40:34
 * @Description 展臂/收臂 展臂extend,shrink收臂
 */
export const robotArmAct = data => {
  return axios({
    url: `/eprdms/control/robot/arm`,
    method: "put",
    data
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 14:41:09
 * @Description 单臂，双臂电流压紧接口 偶臂evencurrentpressup、奇臂oddcurrentpressup，双臂currentpressup电流压紧
 */
export const currentpressup = params => {
  return axios({
    url: "/eprdms/control/robot/currentpressup",
    method: "put",
    params
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 14:41:34
 * @Description 偶臂压紧或者松开锁臂和松臂 偶臂压紧evenpressup,偶臂松开evenpressdown,偶臂锁臂evenlock,偶臂松臂evenunlock
 */
export const evenArmAct = data => {
  return axios({
    url: `/eprdms/control/robot/even`,
    method: "put",
    data
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 14:42:21
 * @Description 奇臂压紧或者松开锁臂和松臂 奇臂压紧oddpressup,奇臂松开oddpressdown,奇臂锁臂oddlock,奇臂松臂oddunlock
 */
export const oddArmAct = data => {
  return axios({
    url: `/eprdms/control/robot/odd`,
    method: "put",
    data
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 14:42:31
 * @Description 奇臂/偶臂内碰收臂 偶臂内碰收臂eveninnershrink、奇臂内碰收臂oddinnershrink
 */
export const innershrink = params => {
  return axios({
    url: `/eprdms/control/robot/innershrink`,
    method: "put",
    params
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 14:43:59
 * @Description 外碰展臂
 */
export const outerextend = params => {
  return axios({
    url: `/eprdms/control/robot/outerextend`,
    method: "put",
    params
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 14:44:29
 * @Description 单臂双臂轻压护线 偶臂evenlightlypressup、奇臂oddlightlypressup，双臂lightlypressup轻压
 */
export const lightlypressup = params => {
  return axios({
    url: `/eprdms/control/robot/lightlypressup`,
    method: "put",
    params
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 14:45:09
 * @Description 双臂完全松开/ 双臂完全压紧/小步轻压 双臂完全松开cmd=pressdown, 双臂完全压紧cmd=completepressup,小步轻压cmd=littleressup
 */
export const pressArm = params => {
  return axios({
    url: `/eprdms/control/robot/press`,
    method: "put",
    params
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 14:46:18
 * @Description 查询指定充电点信息
 */
export const getChargeInfos = params => {
  const { robotMac } = params;
  return axios({
    url: `/eprdms/control/charge/${robotMac}/getChargeInfos`,
    method: "get"
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 14:47:11
 * @Description 添加充电信息
 */
export const addCharge = data => {
  return axios({
    url: `/eprdms/control/charge/addCharge`,
    method: "post",
    data
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 14:47:37
 * @Description 删除充电信息
 */
export const deleteCharge = params => {
  return axios({
    url: `/eprdms/control/charge/deleteCharge`,
    method: "delete",
    params
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 14:48:08
 * @Description 去充电
 */
export const toCharge = params => {
  return axios({
    url: `/eprdms/control/robot/toCharge`,
    method: "put",
    params
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 14:48:29
 * @Description 获取云台位置
 */
export const ptzGetpos = params => {
  return axios({
    url: "/eprdms/control/ptz/getpos",
    method: "put",
    params
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 14:48:39
 * @Description 设置云台位置
 */
export const ptzSetpos = params => {
  return axios({
    url: "/eprdms/control/ptz/setpos",
    method: "put",
    params
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 14:50:06
 * @Description 查询所有巡检模板
 */
export const getAllTemplate = params => {
  return axios({
    url: `/eprdms/plan/template/get`,
    method: "get",
    params
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 14:50:29
 * @Description 获取模板详情
 */
export const getTemplateDetails = params => {
  return axios({
    url: `/eprdms/plan/template/get/detail`,
    method: "get",
    params
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 14:53:07
 * @Description 根据线路id查询机器人
 */
export const getRobotsByLineId = params => {
  return axios({
    url: `/eprdms/device/robot/get/by/line`,
    method: "get",
    params
  });
};


/**
 * @Author KR0288
 * @Date 2022/05/18 14:55:02
 * @Description 视觉模式--录制模式 开始
 */
export const postNavigateRecord = data => {
  return axios({
    url: "/eprdms/control/navigate/record",
    method: "post",
    data
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 14:55:31
 * @Description 视觉模式--录制模式 结束
 */
export const postNavigateEndrecord = data => {
  return axios({
    url: "/eprdms/control/navigate/endrecord",
    method: "post",
    data
  });
};


/**
 * @Author KR0288
 * @Date 2022/05/18 14:56:23
 * @Description 视觉模式--数据库模式 开始
 */
export const postNavigateDatabase = data => {
  return axios({
    url: "/eprdms/control/navigate/database",
    method: "post",
    data
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 14:56:39
 * @Description 视觉模式--数据库模式 结束
 */
export const postNavigateEndEndrecord = data => {
  return axios({
    url: "/eprdms/control/navigate/enddatabase",
    method: "post",
    data
  });
};


/**
 * @Author KR0288
 * @Date 2022/05/18 14:57:00
 * @Description 视觉模式--视频播放
 */
export const postNavigatePlay = data => {
  return axios({
    url: "/eprdms/control/navigate/play",
    method: "post",
    data: data,
    headers: {
      robotMac: data.robotMac
    }
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 14:57:17
 * @Description 视觉模式--视频停止播放
 */
export const postNavigateStopPlay = data => {
  return axios({
    url: "/eprdms/control/navigate/stopplay",
    method: "post",
    data: data,
    headers: {
      robotMac: data.robotMac
    }
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 14:57:51
 * @Description 查询机器当前在线状态
 */
export const getStatus = data => {
  return axios({
    url: "/eprdms/device/restart/get/status",
    method: "post",
    data: data
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 14:58:08
 * @Description 修改机器在线状态
 */
export const setStatus = data => {
  return axios({
    url: "/eprdms/device/restart/set/status",
    method: "post",
    data: data
  });
};

/**
 * @description: 展臂、收臂
 * @date 2023-08-17 10:10
 */
export const setShrinkExtendApi = data => {
  return axios({
    url: "/eprdms/control/robot/arm",
    method: "put",
    data: data
  });
}


/**
 * @description: 分布式机器人摄像头开关
 * @date 2023-09-05 14:57
 */
export const switchCameraApi = (data, { Authorization, Ctrl_Authorization }) => {
  return axios({
    url: "/eprdms/control/robot/camera",
    method: "get",
    params: data,
    headers: {
      Authorization,
      'Ctrl-Authorization': Ctrl_Authorization
    }
  });
}

/**
 * @description: 分布式机器人电源开关
 * @date 2023-09-05 14:57
 */
export const switchAdminApi = (data, { Authorization, Ctrl_Authorization }) => {
  return axios({
    url: "/eprdms/control/robot/branch",
    method: "get",
    params: data,
    headers: {
      Authorization,
      'Ctrl-Authorization': Ctrl_Authorization
    }
  });
}
