<template>
  <div class="login-page">
    <div class="login-form">
      <div class="login-input">
        <h2>巡检机器人后台数据管理系统</h2>
        <el-form style="width: 70%" :model="loginForm" :rules="rules" ref="loginForm" label-width="80px" label-position="left">
          <el-form-item label="用户名" prop="userName" class="code-position">
            <i class="account-icon"></i>
            <el-input v-model="loginForm.userName" @keyup.native.enter="loginConfirm('loginForm')" autocomplete="off"></el-input>
            <div class="input-line"></div>
            <div class="label-line"></div>
          </el-form-item>
          <el-form-item label="密码" prop="password" class="code-position">
            <i class="password-icon"></i>
            <el-input :type="passowrdType" v-model="loginForm.password" @keyup.native.enter="loginConfirm('loginForm')" autocomplete="off"></el-input>
            <div class="password-eye" :class="isSee ? 'cansee' : 'notsee'" @click="seePassword"></div>
            <div class="input-line"></div>
            <div class="label-line"></div>
          </el-form-item>
          <el-form-item label="验证码" prop="code" class="code-position">
            <i class="imgcode-icon"></i>
            <el-input v-model="loginForm.code" @keyup.native.enter="loginConfirm('loginForm')"></el-input>
            <img :src="codeImg" alt="验证码" @click="getImgCode" />
            <div class="input-line"></div>
            <div class="label-line"></div>
          </el-form-item>
          <el-form-item>
            <!-- <el-button @click="resetForm('loginForm')">重 置</el-button> -->
          </el-form-item>
        </el-form>
        <el-button :disabled="isLogging" type="primary" @click="loginConfirm('loginForm')" class="login-button">{{isLogging ? "登 录 中 ..." :  "登 录"}}</el-button>
      </div>
    </div>
    <div class="login-bottom">
      <span>
        版权所属:
        <span v-html="'\u00a0\u00a0'"></span>
        <a href="http://www.keystarirobot.com">广东科凯达智能机器人有限公司</a>
        <span v-html="'\u00a0\u00a0'"></span>
        <a href="https://beian.miit.gov.cn/">粤ICP备19069665号</a>
      </span>
    </div>
  </div>
</template>
<script>
const CryptoJS = require("crypto-js"); //引用AES源码js
import moment from "moment";
import { mapActions, mapMutations, mapState } from "vuex";
import { getValidateCode, login, getWebPermission } from "../http/api.js";
import Cookies from "js-cookie";
import config from "@/http/config.js";
import {
  getSecretApi
} from "@/http/analysisApi"
export default {
  name: "login",
  data() {
    return {
      isLogging: false,
      isShow: false,
      isSee: false, //密码是否可
      passowrdType: "password", //
      loginForm: {
        userName: "",
        password: "",
        code: ""
      },
      rules: {
        userName: [{ required: true, message: "用户名不能为空", trigger: "blur" }],
        password: [{ required: true, message: "密码不能为空", trigger: "blur" }],
        code: [{ required: true, message: "验证码不能为空", trigger: "blur" }]
      },
      nowTime: "",
      timer: null,
      codeImg: ""
    };
  },
  computed: {
    ...mapState(["identity", "Secret", "appKey", "encryptIv", "visitorId",  "tabsRoutesList"])
  },

  methods: {
    ...mapMutations(["updateIsLogin", "getTabsRoutesList"]),
    ...mapActions(["addRoutes"]),

    seePassword() {
      this.isSee = !this.isSee;
      this.passowrdType = this.isSee ? "text" : "password";
    },
    getTime() {
      this.timer = setInterval(() => {
        this.nowTime = moment().format("YYYY-MM-DD HH:mm:ss dddd");
      }, 1000);
    },

    async getImgCode() {
      this.loginForm.code = "",
      getValidateCode().then(res => {
        this.codeImg = window.URL.createObjectURL(res);
      });
    },

    // 密码加密
    encode(word) {
      const key = CryptoJS.enc.Utf8.parse(this.Secret); //十六位十六进制数作为密钥
      const iv = CryptoJS.enc.Utf8.parse(this.encryptIv); //十六位十六进制数作为密钥偏移量
      let srcs = CryptoJS.enc.Utf8.parse(word);
      let encrypted = CryptoJS.AES.encrypt(srcs, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      });
      return CryptoJS.enc.Base64.stringify(encrypted.ciphertext);
    },


    async loginConfirm(formName) {
      
      var enpassword = this.encode(this.loginForm.password);
      var enAppKey = this.encode(this.appKey);
      this.$refs[formName].validate(valid => {
        if (valid) {
           this.isLogging = true;
          let params = {
            userName: this.loginForm.userName,
            password: enpassword,
            identity: this.identity,
            code: this.loginForm.code,
            key: enAppKey,
            forceLogin: false
          };
          login(params).then(res => {
            if (res.code == "000000") {
              this.$message.success(res.data.message);
              this.successLogin(params.userName, res.data);
              this.addRoutes().then(result => {
                this.getTabsRoutesList();
                if(this.tabsRoutesList.length !== 0) {
                  this.$router.push(this.tabsRoutesList[0].path);
                } else {
                  this.$router.push('/404');
                }
              }).catch(err => {
                console.log(err);
                this.$message.error('获取路由失败');
              })
              getSecretApi({}).then(res => {
                if (res.code === "000000") {
                  sessionStorage.setItem("imgSecret", res.data.secret);
                } else {
                  this.$message.error(res.data)
                }
              }).catch(err => {
                console.log(err);
              })
            } else if (res.code == "040002") {
              this.forceLogin(params, res);

            } else {
              console.log(res);
              this.$message.error(res.data);
            }
          }).catch(err => {
            console.log(err);
            this.$message.error("登录异常！");
            this.getImgCode();
          }).finally(() => {
            this.isLogging = false;
          });
        } else {
          console.log("error submit!!");
          return false;
        }
        
      });
    },

    // 强制登录方法
    forceLogin(params, res) {
      this.isLogging = true;
      params.forceLogin = true;
      this.$confirm(res.data, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          login(params)
            .then(res => {
              if (res.code == "000000") {
                localStorage.setItem("userInfo", JSON.stringify({ userName: params.userName }));
                this.$message.success("登录成功！");
                sessionStorage.setItem("Authorization", res.data.authToken);
                sessionStorage.setItem("refreshToken", res.data.refreshToken);
              } else {
                this.$message.error(res.data);
              }
            })
            .catch(err => {
              console.log(err);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消登录"
          });
        }).finally(() => {
          this.isLogging = false;
        })
        ;
    },

    resetForm(formName) {
      this.$refs[formName].resetFields();
    },

    //登录成功后的动作
    successLogin(userName, data) {
      localStorage.setItem("userInfo", JSON.stringify({ userName: userName }));
      sessionStorage.setItem("Authorization", config.tokenPrefix + " " + data.authToken);
      this.$store.commit("saveUserMessage", userName);
      this.updateIsLogin(true);
    },
    //获取url上的参数
    getUrlQuery() {
      const query = window.location.search.substring(1);
      if (query) {
        let params = {};
        const vars = query.split("&");
        vars.forEach(item => {
          const pair = item.split("=");
          params[pair[0]] = pair[1];
        });
        if (params.authorization && params.userName) {
          this.$router.push({ path: "home", query: { userName: params.userName, authorization: params.authorization } });
        }
      }
    }
  },
  mounted() {
    this.getTime();
    this.getImgCode();
  },
  created() {
    this.getUrlQuery();
    let currentCtrlRobot = { robotMac: "", robotName: "", robotType: 1 };
    this.$store.commit("updateCurrCtrlRobot", currentCtrlRobot);
    this.$store.commit("loginRobot", ""); //清空全局的已登录机器人token
    localStorage.removeItem("CtrledRobot");
    Cookies.remove("KR_Robot_Ctrl_token"); //清空本地机器人token
    this.$store.commit("updateSensorstate", {
      cpu_temperature: "",
      sensortemperature: "",
      velocity: "40.4",
      velocityPercent: "100%",
      gradient: ""
    });
  },
  beforeDestroy() {
    clearInterval(this.timer);
  }
};
</script>
<style scoped lang="scss">
.login-page {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  .login-form {
    margin-right: 10vw;
    .login-input {
      width: 29vw;
      height: 63vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #fff;
      h2 {
        font-size: 1.8vw;
      }
    }
  }
  .login-bottom {
    position: fixed;
    bottom: 15px;
    color: #ffff;
    left: 50%;
    transform: translateX(-50%);
    font-size: 14px;
    a {
      color: #ffff;
      text-decoration: none;
    }
    a:hover {
      color: #73dadd;
    }
  }
}
.code-position {
  position: relative;
}
.code-position .account-icon {
  width: 2vw;
  height: 2vw;
  position: absolute;
  left: -7vw;
}
.code-position .password-icon {
  width: 2vw;
  height: 2vw;
  position: absolute;
  left: -7vw;
}
.code-position .imgcode-icon {
  width: 2vw;
  height: 2vw;
  position: absolute;
  left: -7vw;
}
.code-position .password-eye {
  position: absolute;
  right: 0%;
  top: 20%;
  width: 10%;
  height: 70%;
  border: none;
  cursor: pointer;
}
.password-eye.cansee {
  background: url("~@/assets/imgs/common/password-cansee.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
}
.password-eye.notsee {
  background: url("~@/assets/imgs/common/password-cannot.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
}
.code-position img {
  position: absolute;
  right: -5%;
  bottom: 1%;
}

.input-line {
  position: absolute;
  left: -4vw;
  width: 21vw;
  height: 0.4vh;
  background-color: #ffffff;
  opacity: 0.3;
}
.label-line {
  position: absolute;
  left: -4vw;
  width: 4vw;
  height: 0.4vh;
  background-color: #ffffff;
  border-radius: 5vh;
}
</style>
