import Vue from "vue";
import Vuex from "vuex";
import { getWebPermission, getDictDataByTypeApi } from "../http/api";
import router from '../router/index'
import { getRoutes } from '../utils/js/tool'
import moment from "moment";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    currentMenu: "", // 当前菜单
    visitorId: "", // 浏览器唯一ID
    identity: "", // 验证码标识符
    Secret: "", // 验证码加密秘钥,
    imgSecret: "",  // 图片防盗链密钥
    encryptIv: "269C8CBBD96D92AE", // AES 加密偏移值
    appKey: "d0052446-6006-4b37-a3f9-bb658f3a0349", //应用秘钥
    routesDataList: [],     // 页面路由信息
    tabsRoutesList: [],     // 页面tabs路由
    menuRouterLoaded: false,  // 是否加载了动态路由数据
    userMessage: {
      userName: ""
    },
    isLogin: false,         // 是否已经登录页面

    /*
     * @Date 2022/06/08 08:57:38
     * 告警通知信息
     */
    warnToken: '',          // 告警登录的token
    warnId: '',             // 告警id,
    warnImgName: '',        // 告警图片名称
    warnReportName: '',    // 告警报告mingcheng


    /*
     * @Date 2022/07/07 13:53:17
     * 机器状态信息(巡检计划的机器状态)
     */
    robotStatus: {
      robotName: "",
      key: "",
      id: "",
      velocity: "",
      gpsPos: '',
      currentTowernumber: "-1",
      currentDistance: "-1",
      nextTowernumber: "-1",
      nextDistance: "-1"
    }, 

    webElements: [],         // 用户页面权限

    weatherStationList: [], // 订阅的气象基站列表
    weatherStationDataList: [], // 订阅的气象基站响应数据
    weatherData: {
      // 城市天气没有降雨量、大气压强和光照强度
      stationName: "1#塔",
      stationMac: "1#塔",
      temperature: 30,
      humidity: 90,
      windSpeed: 25,
      windDirection: 300,
      rainFall: 10,
      pressure: 1000,
      light: 287,
    }, // 展示的气象数据
    city: "", // 定位的城市
    cityWeatherData: {
      // 城市天气没有降雨量、大气压强和光照强度
      city: "未定位",
      temperature: "--",
      humidity: "--",
      windSpeed: "--",
      windDirection: "--",
      type: "--"
    },
    lineId: "",  // 主界面选中的线路ID
    bureauId: "", //登录用户的供电局id,用于显示机器人设备管理的太阳能基站表格

    compressFile: "", // 压缩文件


    /*
     * @Date 2022/06/15 14:40:28
     * 机器人信息
     */
    allRobotList: [],             // 当前用户下所有机器人列表
    onlineRobotList: [],          // 当前用户下在线的机器人
    currentLoginedRobot: {        // 当前正在登录操作的机器人信息
      robotName: "",
      robotType: "ROBOT_TYPE_25", // 1:50kg 2:25kg
      robotMac: "",               // 机器人mac地址
      robotControlToken: "",      // 登录后获取到的操作机器人token
      count: 100,                 // 位置模式下的范围是100~6900
      velocity: 300,              // 机械臂运动范围是300~18000,机器人前进后退范围300~8000
      model: 1,                   // 模式，1运动模式，2位置模式
      electron: "",               // 当前机器人电量
      h5sToken: {                 // 播放视频的token
        live1: "", //双臂
        live2: "", //可见光
        live3: "", //红外
        live4: "",
        live5: "",
        live6: ""
      } 
    },
    session: "",                 // 机器人登录认证session
    timeout: "",                 // session过期时间
    

    /*
     * @Date 2022/06/15 16:29:32
     * 心跳包（操作日志、视觉模式状态）
     */
    keepalive: {
      sim1delay: "0",
      sim1rsrp: "0",
      sim1state: "4G",
      sim2delay: "0",
      sim2rsrp: "0",
      sim2state: "4G",
      lineName: "",
      current_towernumber: "-1",
      current_distance: "-1",
      next_towernumber: "-1",
      next_distance: "-1",
      navigation: {
        state: "",        // 视频流推送状态
        dir: "",          // 视觉方向
        pattern: ""       // 视觉模式
      }
    }, 

    /*
     * @Date 2022/06/15 16:22:33
     * 机器人传感器推送数据
     */
    socketContent: {
      key: "",        // 标记唯一请求 MAC+时间戳
      id: "",         // 标记唯一设备MAC(客户端)
      topic: "",      // 公网预留,用于MQTT通信,服务端回复请求的主题
      type: "",       // 请求
      cmd: "", //命令字
      result: "", //执行结果1 成功 0 失败 2 设备没有登录
      motorstate: "",   // 电机运动类型,内容参考电机运动类型
      state: "", //电机状态,内容参考电机状态反馈表 0~45
      data: {
        //命令对应需求的数据项
        velocity: "", //电压
        count: "", //运动距离
        cameraauto: "", //摄像头自动标志
        model: null,
        swversion: null,
        sensorvalue: {
          //传感器值
          oddoutertouch: "", //奇臂外碰
          oddinnertouch: "", //奇臂内碰
          oddpressup: "", //奇臂压紧上到位
          oddpressdown: "", //奇臂下降到位
          oddlock: "", //奇臂锁臂到位
          oddunlock: "", //奇臂松臂到位
          evenoutertouch: "", //偶臂外碰
          eveninnertouch: "", //偶臂内碰
          evenpressup: "", //偶臂压紧上到位
          evenpressdown: "", //偶臂下降到位
          evenlock: "", //偶臂锁臂到位
          evenunlock: "", //偶臂松臂到位
          crossextend: "", //展开到位
          crossshrink: "", //收缩到位
          ultrasonicodd: "", //奇臂超声
          ultrasoniceven: "", //偶臂超声
          slidodd: "", //奇臂打滑
          slideven: "", //偶臂打滑
          charge: "" //充电
        },
        robotpose: {
          walkdir: "0", //行走方向   1 前 2 后
          walkstatus: "0", //行走状态	0 进行中 1 传感器到位 2 超过传感器
          oddpressdir: "0", //奇臂压紧方向
          oddpressstatus: "0", //奇臂压紧状态
          oddlockdir: "0", //奇臂锁臂方向
          oddlockstatus: "0", //奇臂锁臂状态
          evenpressdir: "0", //偶臂压紧方向
          evenpressstatus: "0", //偶臂压紧状态
          evenlockdir: "0", //偶臂锁臂方向
          evenlockstatus: "0", //偶臂锁臂状态
          crossdir: "0", //展臂方向
          crossstate: "0" //展臂状态
        },

        // 摄像头充电模块
        fpga: {
          evenInsideCameraState: false,
          evenNavigationCameraState: false,
          evenOutsideCameraState: false,
          evenTofCameraState: false,
          evenWheelCameraState: false,
          oddInsideCameraState: false,
          oddNavigationCameraState: false,
          oddOutsideCameraState: false,
          oddTofCameraState: false,
          oddWheelCameraState: false
        },

        // 管理模块
        pcu: {
          auxState: false,
          btdState: false,
          evenState: false,
          evenXiLinxState: false,
          ksmState: false,
          laserState: false,
          nxState: false,
          oddState: false,
          oddXiLinxState: false,
          tofState: false,
          ytState: false
        }
      },
    },

    /*
      * @Date 2022/07/21 10:15:32
      * 视频画框
      */
    videoTag: {
      position: null,     // 视频画框的坐标
      resolution: null,   // 视频画框的分辨率
    },

    /*
     * @Date 2022/06/15 16:15:17
     * 机器人传感器推送数据(操作日志)
     */
    sensorstate: {
      cpu_temperature: "",
      sensortemperature: "",
      velocity: '40.4',
      velocityPercent: "100%",
      gradient: ""
    },

    /*
     * @date 2023-10-20 16:36
     * @description: 操作日志的经纬度
     */
    robotAddress: {
      latitude: "",
      longitude: ""
    },


    /*
     * @Date 2022/06/15 16:31:41
     * 错误日志
     */
    errorContent: {},  


    /*
     * @Date 2022/07/07 14:55:12
     * 轻量机器人重启模块信息
     */
    slightRobotRst: {
      isResponse: false,  // 是否响应数据
      option: "",         // 操作类型：SET-设置继电器状态，GET-查询继电器状态
      rstMac: "",         // 重启模块MAC
      isOnline: false,    // 重启模块在线状态：true-在线，false-离线
      result: "",         // 响应结果：ON-继电器状态为开，OFF-继电器状态为关，TIMEOUT-等待继电器响应超时
    },

    /**
     * @description: 分布式机器人休眠唤醒信息
     * @date 2023-08-16 17:02
     */
    sleepWaveUpMsg: {
      log: "",
      logTime: ""
    },

    snapPictureData: {},    // 拍照弹出信息
    baseStationMessage: [   // 充电基站数组
      // {
      //   charge_current: 41.09,
      //   controlTemp2: 0,
      //   date: 0,
      //   controlTemp1: 0,
      //   motorPower: 0,
      //   battery_voltage: 40,
      //   oddTemp: 0,
      //   controlPower: 0,
      //   robotState: 0,
      //   electric: 0,
      //   num: 383,
      //   channel: 0,
      //   evenTemp: 0,
      //   battery_charge: 0,
      //   robot_charge: 1,
      //   ptzPowerState: 0,
      //   solar_current: 12.9988,
      //   taskId: 0,
      //   desc: "xxx",
      //   solar_charge: 1,
      //   robotMac: "48b02d0ec5d4"
      // }
    ], 
    robotpose: {
      walkdir: "0", //行走方向   1 前 2 后
      walkstatus: "0", //行走状态	0 进行中 1 传感器到位 2 超过传感器
      oddpressdir: "0", //奇臂压紧方向
      oddpressstatus: "0", //奇臂压紧状态
      oddlockdir: "0", //奇臂锁臂方向
      oddlockstatus: "0", //奇臂锁臂状态
      evenpressdir: "0", //偶臂压紧方向
      evenpressstatus: "0", //偶臂压紧状态
      evenlockdir: "0", //偶臂锁臂方向
      evenlockstatus: "0", //偶臂锁臂状态
      crossdir: "0", //展臂方向
      crossstate: "0" //展臂状态
    },
    weather: {},
    ptzMsg: {
      //云台信息
      abscissa: 0, //云台横坐标，0~3600
      ordinate: 0, //云台纵坐标，0~3600
      uart: 3, //云台操作串口 3:可见光 5：红外 0：两个一起
      velocity: 400 //速度模式下有效值0~400
    },


    deleteImgList: [],  // 可选择删除图案列表
    cameraTypeList: [], // 摄像头类型列表


  },
  mutations: {
    /*
     * @Date 2022/04/02 15:27:42
     * 更新当前菜单
     */
    updateCurrentMenu (state, menu) {
      state.currentMenu = menu;
    },
    // 更新浏览器ID
    updateVisitorId (state, visitorId) {
      state.visitorId = visitorId;
    },
    // 验证码标识符
    saveCodeIdentity (state, identity) {
      state.identity = identity;
    },
    // 验证码加密秘钥
    saveSecret (state, Secret) {
      state.Secret = Secret;
    },
    // 登录成功保存用户信息
    saveUserMessage (state, name) {
      state.userMessage.userName = name;
    },
    saveBureauId (state, bureauId) {
      state.bureauId = bureauId;
    },
    // 保存压缩文件
    saveCompressFile(state, file) {
      state.compressFile = file;
    },

    // 保存imgSecret
    saveImgSecret(state, imgSecret) {
      state.imgSecret = imgSecret;
    },

    /*
     * @Date 2022/07/27 13:49:51
     * 更新是否有路由信息
     */
    updateMenuRouterLoaded(state, menuRouterLoaded) {
      state.menuRouterLoaded = menuRouterLoaded
    },

    /*
     * @Date 2022/06/22 10:41:40
     * 是否已经登录页面
     */
    updateIsLogin(state, flag) {
      state.isLogin = flag;
    },


    /*
     * @Date 2022/05/26 17:38:34
     * 修改告警登录token
     */
    updateWarnToken (state, newToken) {
      state.warnToken = newToken;
    },


    /*
     * @Date 2022/05/26 17:41:05
     * 修改告警ID
     */
    updateWarnId (state, newId) {
      state.warnId = newId;
    },


    /*
     * @Date 2022/06/08 08:56:40
     * 修改当前告警照片名称
     */
    updateWarnImgName (state, name) {
      state.warnImgName = name;
    },


    /*
     * @Date 2022/06/08 09:37:00
     * 修改当前告警报告名称
     */
    updateWarnReportName (state, name) {
      state.warnReportName = name;
    },

    /*
     * @Date 2022/03/22 15:21:09
     * 更新定位城市
     */
    updateCity (state, city) {
      state.city = city;
    },
    /*
     * @Date 2022/03/21 14:42:14
     * 更新城市天气预报信息
     */
    updateCityWeather (state, weather) {
      state.cityWeatherData = weather;
    },
    /*
     * @Date 2022/03/22 15:54:25
     * 更新订阅的气象基站列表
     */
    updateWeatherStationList (state, weatherStationList) {
      state.weatherStationList = weatherStationList;
      state.weatherStationDataList = [];
    },
    /*
     * @Date 2022/03/22 16:19:49
     * 更新订阅的气象基站列表
     */
    updateWeatherStationDataList (state, data) {
      if (data) {
        let index = state.weatherStationDataList.findIndex(s => { return s.deviceMac.indexOf(data.deviceMac) > -1 });
        if (index > -1) {
          state.weatherStationDataList.splice(index, 1);
        }
        state.weatherStationDataList.push(data);
      }
    },

    /*
     * @Date 2022/04/15 13:30:45
     * 更新订阅的机器人GPS地址
     */
    updateRobotGpsPos (state, data) {
      state.robotStatus.gpsPos = `${Number(data.longitude).toFixed(8)},${Number(data.latitude).toFixed(8)}}`
    },

    /*
     * @Date 2022/03/14 16:41:36
     * 保存线路ID
     */
    saveLineId (state, lineId) {
      state.lineId = lineId;
    },




    /**
     * @Author KR0288
     * @Date 2022/07/07 11:31:03
     * @Description 机器人上下线主题，推送消息过来时更新在线机器人
     */
    updateOnlineRobot (state, online) {
      console.log('"result"', online[0]);
      online = online[0];
      var flag;
      if (online.result === "1") {
        if (state.onlineRobotList.length === 0) {
          let r = state.allRobotList.find(item => {
            return item.robotMac === online.id;
          });
          state.onlineRobotList.push(r);
        } else {
          flag = state.onlineRobotList.some(item => {
            return item.robotMac === online.id;
          });
          if (!flag) {
            let r = state.allRobotList.find(item => {
              return item.robotMac === online.id;
            });
            state.onlineRobotList.push(r);
          }
        }
      } else if (online.result === "0") {
        for (let i = 0; i < state.onlineRobotList.length; i++) {
          if (state.onlineRobotList[i].robotMac === online.id) {
            state.onlineRobotList.splice(i, 1);
          }
        }
      }
    },

    /**
     * @Author KR0288
     * @Date 2022/07/07 11:29:55
     * @Description 保存所有机器人
     */
    saveAllRobot (state, allRobot) {
      state.allRobotList = allRobot;
    },

    /**
     * @Author KR0288
     * @Date 2022/07/07 11:30:02
     * @Description 保存在线机器人
     */
    saveOnlineRobot (state, onlineRobot) {
      state.onlineRobotList = onlineRobot;
    },

    updateCurrentLoginedRobot(state, currentLoginedRobot) {
      state.currentLoginedRobot = currentLoginedRobot
    },

    /**
     * @Author KR0288
     * @Date 2022/07/07 11:33:30
     * @Description 登录机器人推送的机器控制token
     */
    loginRobot (state, token) {
      state.currentLoginedRobot.robotControlToken = token;
      if (token == "") {
        state.currentLoginedRobot.h5sToken = {
          live1: "", //双臂
          live2: "", //可见光
          live3: "", //红外
          live4: "",
          live5: "",
          live6: ""
        };
        state.currentLoginedRobot.robotMac = "";
        state.currentLoginedRobot.robotName = "";
      }
    },

    /**
     * @Author KR0288
     * @Date 2022/07/07 11:34:13
     * @Description 接口登录机器人成功后保存返回的信息
     */
    updateCurrCtrlRobot (state, currRobot) {
      state.currentLoginedRobot.robotMac = currRobot.robotMac;
      state.currentLoginedRobot.robotName = currRobot.robotName;
      state.currentLoginedRobot.robotType = currRobot.robotType;
      state.currentLoginedRobot.h5sToken = {
        live1: `${currRobot.robotMac}_live1`,
        live2: `${currRobot.robotMac}_live2`,
        live3: `${currRobot.robotMac}_live3`,
        live4: `${currRobot.robotMac}_live4`,
        live5: `${currRobot.robotMac}_live5`,
        live6: `${currRobot.robotMac}_live6`
      };
    },

    /**
     * @Author KR0288
     * @Date 2022/07/07 11:39:15
     * @Description 更新h5s服务登录认证session
     */
    updateSession(state, session) {
      state.session = session;
    },

    /**
     * @Author KR0288
     * @Date 2022/07/07 11:39:20
     * @Description 更新h5s服务有效时间
     */
    updateTimeout(state, timeout) {
      state.timeout = timeout
    },

    /**
     * @Author KR0288
     * @Date 2022/07/07 11:46:41
     * @Description 更新当前登录机器人所有状态信息(操作日志、视觉模式状态)
     */
    updateKeepalive (state, keepalive) {
      state.keepalive.sim1delay = keepalive.sim1delay;
      state.keepalive.sim1rsrp = keepalive.sim1rsrp;
      state.keepalive.sim1state = keepalive.sim1state;
      state.keepalive.sim2delay = keepalive.sim2delay;
      state.keepalive.sim2rsrp = keepalive.sim2rsrp;
      state.keepalive.sim2state = keepalive.sim2state;
      state.keepalive.current_towernumber = keepalive.current_towernumber;
      state.keepalive.current_distance = keepalive.current_distance;
      state.keepalive.next_towernumber = keepalive.next_towernumber;
      state.keepalive.next_distance = keepalive.next_distance;
      state.keepalive.lineName = keepalive.lineName;
      state.keepalive.navigation = keepalive.navigation
    },

    /**
     * @Author KR0288
     * @Date 2022/07/07 13:30:37
     * @Description 更新传感器信息(操作日志)
     */
    updateSensorstate (state, sensorstate) {
      state.sensorstate.cpu_temperature = sensorstate.cpu_temperature;
      state.sensorstate.sensortemperature = sensorstate.sensortemperature;
      state.sensorstate.velocity = sensorstate.velocity;
      state.sensorstate.velocityPercent = sensorstate.velocityPercent;
      state.sensorstate.gradient = sensorstate.gradient;
    },

    /**
     * @Author KR0288
     * @Date 2022/07/08 10:56:37
     * @Description 保存机器人状态推送信息
     */
    saveSocketMsg (state, socketContent) {
      state.socketContent.motorstate = socketContent.motorstate;
      state.socketContent.data.count = socketContent.data.count;
      state.socketContent.data.sensorvalue = socketContent.data.sensorvalue;
      state.socketContent.data.fpga = socketContent.data.fpga;
      state.socketContent.data.pcu = socketContent.data.pcu;
    },

    /**
     * @description: 单独更新pcu
     * @date 2023-10-16 15:02
     */
    updatePCUOfSensorState(state, pcuContent) {
      state.socketContent.data.sensorvalue.pcu = pcuContent;
    },

    /**
     * @Author KR0288
     * @Date 2022/07/07 11:38:17
     * @Description 保存心跳返回的机器状态（电量、所处塔杆位置）：巡检计划
     */
    saveRobotStatus (state, content) {
      let rt = state.onlineRobotList.filter(item => item.robotMac == content.id);
      console.log(content, rt);
      if (rt.length > 0) {
        let tempObj = {
          id: content.id,
          key: content.key,
          robotName: rt[0].robotName,
          velocity: 0,
          currentTowernumber: content.data.current_towernumber,
          currentDistance: content.data.current_distance,
          nextTowernumber: content.data.next_towernumber,
          nextDistance: content.data.next_distance
        }

        //机器满电是420 ，单位0.1V
        //电量显示百分比
        var preValue;
        if(state.currentLoginedRobot.robotMac == content.id) {
          preValue = state.sensorstate.velocityPercent
        } else {
          preValue = "0%";
        }
        // 电量为负数时或大于1，数据发生异常！
        if(Number(preValue.replace("%", "")) >= 0 && Number(preValue.replace("%", "")) <= 100) {
          tempObj.velocity = preValue;
        }else {
          tempObj.velocity = "Invalid Velocity（"+preValue+"）";
        }      
        state.robotStatus = tempObj;
      }
    },

    /*
     * @Date 2022/07/21 10:17:46
     * 保存视频画框信息
     */
    saveVideoDrawTag(state, socketContent) {
      state.videoTag = socketContent
    },

    // 云台坐标
    updatePtzPos (state, ptzPosi) {
      state.ptzMsg.abscissa = ptzPosi.abscissa;
      state.ptzMsg.ordinate = ptzPosi.ordinate;
    },
    // 更新天气信息
    updateWeatherSensor (state, weather) {
      state.weather = weather;
    },

    // 更改机器人运动模式
    updateRobotMode (state, model) {
      state.currentLoginedRobot.model = model;
    },
    // 更改机器人运动速度
    updateRobotVelocity (state, velocity) {
      state.currentLoginedRobot.velocity = velocity;
    },
    // 更改机器人运动位置
    updateRobotCount (state, count) {
      state.currentLoginedRobot.count = count;
    },
    // // 修改云台串口号
    // updateRobotCount(state,uart){
    //   state.currentLoginedRobot.uart = uart;
    // },
    
    /**
     * @Author KR0288
     * @Date 2022/06/16 16:15:06
     * @Description 保存推送来的抓拍信息
     */
    snapPicture (state, socketContent) {
      console.log(socketContent);
      if (socketContent.data.name) {
        state.snapPictureData = socketContent;
      }
    },
    
 
    // 保存传感器信息
    saveSensor (state, socketContent) {
      state.socketContent = socketContent;
      state.currentLoginedRobot.electron = socketContent.data.velocity; //电压
    },

    // 格式化传感信息
    initSensor (state) {
      state.socketContent = {
        key: "",        // 标记唯一请求 MAC+时间戳
        id: "",         // 标记唯一设备MAC(客户端)
        topic: "",      // 公网预留,用于MQTT通信,服务端回复请求的主题
        type: "",       // 请求
        cmd: "", //命令字
        result: "", //执行结果1 成功 0 失败 2 设备没有登录
        motorstate: "",   // 电机运动类型,内容参考电机运动类型
        state: "", //电机状态,内容参考电机状态反馈表 0~45
        data: {
          //命令对应需求的数据项
          velocity: "", //电压
          count: "", //运动距离
          cameraauto: "", //摄像头自动标志
          model: null,
          swversion: null,
          sensorvalue: {
            //传感器值
            oddoutertouch: 1, //奇臂外碰
            oddinnertouch: 1, //奇臂内碰
            oddpressup: 1, //奇臂压紧上到位
            oddpressdown: 1, //奇臂下降到位
            oddlock: 1, //奇臂锁臂到位
            oddunlock: 1, //奇臂松臂到位
            evenoutertouch: 1, //偶臂外碰
            eveninnertouch: 1, //偶臂内碰
            evenpressup: 1, //偶臂压紧上到位
            evenpressdown: 1, //偶臂下降到位
            evenlock: 1, //偶臂锁臂到位
            evenunlock: 1, //偶臂松臂到位
            crossextend: 1, //展开到位
            crossshrink: 1, //收缩到位
            ultrasonicodd: 1, //奇臂超声
            ultrasoniceven: 1, //偶臂超声
            slidodd: 1, //奇臂打滑
            slideven: 1, //偶臂打滑
            charge: 1 //充电
          },
          robotpose: {
            walkdir: "0", //行走方向   1 前 2 后
            walkstatus: "0", //行走状态	0 进行中 1 传感器到位 2 超过传感器
            oddpressdir: "0", //奇臂压紧方向
            oddpressstatus: "0", //奇臂压紧状态
            oddlockdir: "0", //奇臂锁臂方向
            oddlockstatus: "0", //奇臂锁臂状态
            evenpressdir: "0", //偶臂压紧方向
            evenpressstatus: "0", //偶臂压紧状态
            evenlockdir: "0", //偶臂锁臂方向
            evenlockstatus: "0", //偶臂锁臂状态
            crossdir: "0", //展臂方向
            crossstate: "0" //展臂状态
          },
          // 摄像头充电模块
          fpga: {
            evenInsideCameraState: false,
            evenNavigationCameraState: false,
            evenOutsideCameraState: false,
            evenTofCameraState: false,
            evenWheelCameraState: false,
            oddInsideCameraState: false,
            oddNavigationCameraState: false,
            oddOutsideCameraState: false,
            oddTofCameraState: false,
            oddWheelCameraState: false
          },

          // 管理模块
          pcu: {
            auxState: false,
            btdState: false,
            evenState: false,
            evenXiLinxState: false,
            ksmState: false,
            laserState: false,
            nxState: false,
            oddState: false,
            oddXiLinxState: false,
            tofState: false,
            ytState: false
          }
        },
      }
    },

    //保存太阳能基站推送信息
    saveBaseStationMessage (state, socketContent) {
      if (state.baseStationMessage.length > 0) {
        //如果数组里已经有值
        let isHad = state.baseStationMessage.some(item => {
          return item.robotMac == socketContent.data.robotMac && item.num == socketContent.data.num;
        });
        if (isHad) {
          //判断推送来的值是不是已经在数组中
          state.baseStationMessage.forEach((item, i) => {
            if (item.robotMac == socketContent.data.robotMac && item.num == socketContent.data.num) {
              state.baseStationMessage.splice(i, 1, socketContent.data); //替换原来的值
            }
          });
        } else {
          state.baseStationMessage.push(socketContent.data);
        }
      } else {
        state.baseStationMessage.push(socketContent.data);
      }
    },
    // 从心跳中保存太阳能基站信息
    saveKeepAliveBaseStationMessage (state, content) {
      state.baseStationMessage = content;
    },


    // 从心跳中保存太阳能基站信息
    saveKeepAliveBaseStationMessage (state, content) {
      state.baseStationMessage = content;
    },

    //desc
    saveDesc (state, content) {
      state.errorContent = content;
    },



    /**
     * @Author KR0132
     * @Date 2022/06/17 10:39:17
     * @Description 保存轻量化机器人响应
     */
    updateSlightRobotRst (state, content) {

      if (state.slightRobotRst.rstMac != "" && content.rstMac == state.slightRobotRst.rstMac) {
        state.slightRobotRst.rstMac = content.rstMac;
        state.slightRobotRst.isResponse = true;
        state.slightRobotRst.isOnline = true;
        switch (content.resultType) {
          case "RST_RESULT_GET_RELAY_ON":
          case "ROBOT_EXP_OFFLINE":
            state.slightRobotRst.option = "GET";
            state.slightRobotRst.result = "ON";
            break;
          case "RST_RESULT_GET_RELAY_OFF":
          case "ROBOT_EXP_ONLINE":
            state.slightRobotRst.option = "GET";
            state.slightRobotRst.result = "OFF";
            break;
          case "RST_RESULT_SET_RELAY_ON":
            state.slightRobotRst.option = "SET";
            state.slightRobotRst.result = "ON";
            break;
          case "RST_RESULT_SET_RELAY_OFF":
            state.slightRobotRst.option = "SET";
            state.slightRobotRst.result = "OFF";
            break;
          case "RESPONSE_WAIT_TIMEOUT":
            state.slightRobotRst.option = "";
            state.slightRobotRst.result = "TIMEOUT";
            state.slightRobotRst.isOnline = false;
            break;
          default:
        }
      }

    },

    /**
     * @Author KR0132
     * @Date 2022/06/17 13:39:30
     * @Description 更新当前监听的重启模块
     */
    updateCurrentSlightRobotRst (state, content) {
      state.slightRobotRst.isResponse = false;
      state.slightRobotRst.rstMac = content.rstMac;
    },

    /**
     * @Author KR0288
     * @Date 2022/07/28 11:18:20
     * @Description 更新路由信息
     */
    saveRoutesData(state, routesData) {
      state.routesDataList = routesData;
    },

    /**
     * @Author KR0288
     * @Date 2022/07/27 13:26:41
     * @Description 获取tabs路由列表
     */
    getTabsRoutesList(state) {
      state.tabsRoutesList = state.routesDataList.filter(item => {
        return item.type === 1;
      }).map(item => {
        let route = {
          name: item.label,
          path: item.path,
        };
        return route          
      }).sort((route_1, route_2) => {
        return route_1.sort < route_2.sort;
      });
    },

    /**
     * @description: 更新分布式机器人唤醒休眠信息
     * @date 2023-08-16 17:05
     */
    updateRobotWaveUpSleepMsg(state, msg) {
      state.sleepWaveUpMsg = {
        logTime: moment().format("hh:mm:ss"),
        log: msg
      }
    },

    /*
     * @date 2023-10-20 16:39
     * @description: 更新当前登录机器人经纬度
     */
    updateRobotAddress(state, addressData) {
      state.robotAddress = addressData;
    }
  },

  actions: {
    /**
     * @Author KR0288
     * @Date 2022/07/11 16:03:56
     * @Description 动态配置路由
     */
    addRoutes({commit, state}) {
      return new Promise((resolve, reject) => {
        let secret = state.appKey;
        getWebPermission({ appKey: secret }).then(res => {
          if(res.code === "000000") {
            console.log(res.data);
            router.addRoutes(getRoutes(res.data));
            commit("updateMenuRouterLoaded", true);
            resolve(true)            
          } else {
            reject(false)
          }
        }).catch(err => {
          console.log(err);
          reject(false)
        })        
      })
    },

    /**
     * @description: 获取字典值
     * @date 2023-08-16 13:52
     */
    getDictDataByType({commit, state}, {
      dataName,
      type
    }) {
      getDictDataByTypeApi({
        type
      }).then(res => {
        if (res.code === "000000") {
          state[dataName] = res.data;
        } else {
          state[dataName] = null;
        }
      }).catch(err => {
        console.log(err);
      })
    }
  },

  getters: {
    /*
     * @Date 2022/06/15 14:31:36
     * 计算出在线机器人和不在线机器人列表并排序，在线机器人在前，不在线机器人在后
     */
    getAllOnlineRobotList: state => {
      // 1、初始化
      let resArr = [];
      let all = JSON.parse(JSON.stringify(state.allRobotList));
      let online = JSON.parse(JSON.stringify(state.onlineRobotList));

      if (all.length > 0) {
        for (let i = 0; i < all.length; i++) {

          // 2、判断机器人是否在线
          if (online.length > 0) {
            for (let j = 0; j < online.length; j++) {
              if (all[i].robotMac == online[j].robotMac) {
                all[i].isOnline = true;
                break;
              } else {
                all[i].isOnline = false;
              }
            }
          } else {
            all[i].isOnline = false;
          }

          // 3、先添加在线后添加离线
          if (all[i].isOnline) {
            resArr.unshift(all[i]);
          } else {
            resArr.push(all[i]);
          }
        }
      }
      return resArr;
    },

    /**
     * @Author KR0288
     * @Date 2022/06/15 15:09:37
     * @Description 判断是否登录成功，按钮是否禁用
     */
    getIsDisabled: state => {
      return state.currentLoginedRobot.robotControlToken == "" ? true : false;
    }
  },

  modules: {}
});