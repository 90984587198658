import axios from "axios"; // 引入axios
import config from "./config.js"; // 导入默认配置
import store from "../store/index";
import qs from "qs"; // 序列化请求数据，视服务端的要求

import { Message } from "element-ui";
import router from "../router/index";
import Cookie from "js-cookie";
import * as browser from "@/utils/js/browser";


/**
 * @Author KR0132
 * @Date 2022/03/31 09:03:35
 * @Description 获取浏览器唯一ID
 */
export async function getBrowserId () {
  let visitorId = await browser.getBrowserId();
  return visitorId;
}

/**
 * @Author KR0288
 * @Date 2022/06/07 14:56:39
 * @Description 封装axios
 */
export default function $axios (options) {
  return new Promise((resolve, reject) => {
    const instance = axios.create(config);

    // 1、request 拦截器
    instance.interceptors.request.use(
      config => {

        // 1.1 获取用户浏览器ID
        let visitorId = store.state.visitorId;
        if (!visitorId) {
          visitorId = getBrowserId();
        }
        config.headers.Visitor = visitorId;

        // 1.2 针对各种请求处理请求头
        if (sessionStorage.getItem("Authorization") && config.url.indexOf('/eprdms/device/alarm/message/') == -1) {
          config.headers.Authorization = sessionStorage.getItem("Authorization");
        }
        if (config.url.indexOf("loadFile/report") > -1 || config.url.indexOf("download") > -1) {
          config.responseType = "blob";
          config.headers['Content-Type'] = "application/x-www-form-urlencoded";
        }

        // 1.2.1 巡检图片下载请求头处理 
        if (config.url.indexOf("/eprdms/sftp/download/zip") > -1) {
          config.responseType = "blob";
          config.headers['Content-Type'] = "application/json";
        }
        if (config.url.indexOf("/eprdms/sftp/download/zip/prepare") > -1 || config.url.indexOf("/eprdms/sftp/cancel/download") > -1) {
          config.responseType = "application/json";
          config.headers['Content-Type'] = "application/json";
        }

        if (config.url.indexOf("deleteTowers") > -1 || config.url.indexOf("logout") > -1) {
          config.data = qs.stringify(config.data)
          config.headers['Content-Type'] = "application/x-www-form-urlencoded";
        }
        if (config.url.indexOf("/auth/v2/login/code") > -1) {
          config.responseType = "blob";
          config.headers["Content-Type"] = "application/x-www-form-urlencoded";
        }
        /*
         * @Date 2022/06/07 13:13:27
         * 处理下载图片和报告
         */
        if (config.type == 'downloadWarning') {
          config.responseType = "blob";
          config.headers["Content-Type"] = "application/json"
        }

        // 1.3 针对机器控制的url处理请求头
        var robotCtrlURL = [
          "/eprdms/control/robot/online",
          "/eprdms/control/robot/login",
          "/eprdms/control/robot/odd",
          "/eprdms/control/robot/walk",
          "/eprdms/control/robot/brake",
          "/eprdms/control/common/aszoom",
          "/eprdms/control/common/asfocus",
          "/eprdms/control/common/autofocus",
          "/eprdms/control/common/snap",
          "/eprdms/control/robot/switch",
          "/eprdms/control/robot/query",
          "/eprdms/control/robot/autorun",
          "/eprdms/control/robot/auto",
          "/eprdms/control/robot/arm",
          "/eprdms/control/robot/currentpressup",
          "/eprdms/control/robot/even",
          "/eprdms/control/robot/odd",
          `/eprdms/control/robot/innershrink`,
          `/eprdms/control/robot/outerextend`,
          `/eprdms/control/robot/lightlypressup`,
          `/eprdms/control/robot/press`,
          "/eprdms/control/charge/addCharge",
          "/eprdms/control/charge/deleteCharge",
          `/eprdms/control/robot/toCharge`,
          "/eprdms/control/ptz/getpos",
          "/eprdms/control/ptz/setpos",
          "/eprdms/control/ptz/walk",
          "/eprdms/control/ptz/stop",
          "/eprdms/control/ptz/init",
          "/eprdms/control/camera/control",
          "/eprdms/control/camera/pull",
          "/eprdms/control/robot/rebootroute",
          "/eprdms/control/station/control",
          "/eprdms/control/navigate/record",
          "/eprdms/control/navigate/endrecord",
          "/eprdms/control/navigate/database",
          "/eprdms/control/navigate/enddatabase",
          "/eprdms/control/navigate/play",
          "/eprdms/control/navigate/stopplay",
          "/eprdms/control/robot/reboot"
        ];
        if (robotCtrlURL.includes(config.url) || config.url.indexOf("getChargeInfos") != -1) {
          config.baseURL = config.ctrlRobotURL;
          config.headers['Ctrl-Authorization'] = Cookie.get("KR_Robot_Ctrl_token");
        }
        if (config.url.indexOf("/eprdms/control/robot/toCharge") > -1) {
          config.headers["Content-Type"] = "application/x-www-form-urlencoded";
        }
        if (config.url == "/eprdms/control/ptz/stop") {
          var reTimer = setTimeout(() => {
            // 防止云台一直转动，再发一次停止命令
            config.baseURL = config.ctrlRobotURL;
            config.headers['Ctrl-Authorization'] = Cookie.get("KR_Robot_Ctrl_token");
            axios.request(config);
            clearTimeout(reTimer);
          }, 400);
        }
        if (config.url.indexOf("api/v1") > -1) {
          delete config.headers.Authorization;
        }
        
        return config;
      },
      error => {
        //  1.判断请求超时
        if (error.code === "ECONNABORTED" && error.message.indexOf("timeout") !== -1) {
          // TODO:根据你设置的timeout/真的请求超时 判断请求现在超时了，你可以在这里加入超时的处理方案, 例如再重复请求一次: return service.request(originalRequest);
        }

        //  2.需要重定向到错误页面
        const errorInfo = error.response;
        if (errorInfo) {
          // error =errorInfo.data//页面那边catch的时候就能拿到详细的错误信息,看最下边的Promise.reject
          // const errorStatus = errorInfo.status; // 404 403 500 ... 等
          // router.push({
          //     path: `/error/${errorStatus}`
          // })
        }
        return Promise.reject(error); // 在调用的那边可以拿到(catch)你想返回的错误信息
      }
    );


    // 2、response 响应拦截器
    instance.interceptors.response.use(
      response => {
        let data;
        // 2.1 IE9时response.data是undefined，因此需要使用response.request.responseText(Stringify后的字符串)
        if (response.data == undefined) {
          data = JSON.parse(response.request.responseText);
        } else {
          data = response.data;
        }

        // 2.2 在登录时存储验证码密钥和验证码
        if (response.config.url.indexOf("/auth/v2/login/code") > -1) {
          store.commit("saveCodeIdentity", response.headers.identity);
          store.commit("saveSecret", response.headers.secret);
        }
        if (response.config.url.indexOf("/eprdms/auth/v2/login") > -1) {
          sessionStorage.setItem("sessionId", response.headers["session-id"]);
        }

        // 2.3 根据返回的code值来做不同的处理（和后端约定）
        if (data.code != "000000" && Object.hasOwnProperty.call(data, "serviceStatusCode")) {
          if (data.serviceStatusCode == "1000500") {
            //清除token
            store.commit("loginRobot", "");
            localStorage.removeItem("CtrledRobot");
            Cookie.remove("KR_Robot_Ctrl_token");
          }
        }

        /*
         * @Date 2022/06/08 09:20:12
         * 下载预警图片时存储图片名字, 下载预警报告存储报告名字
         */
        if (response.config.type == 'downloadWarning' && response.headers['content-disposition']) {
          if (response.config.url.includes('photo')) {
            store.commit('updateWarnImgName', decodeURI(response.headers['content-disposition'].split(';')[1]?.replace('filename=', '')) || '')
          } else if (response.config.url.includes('report')) {
            store.commit('updateWarnReportName', decodeURI(response.headers['content-disposition'].split(';')[1]?.replace('filename=', '')) || '')
          }
        }
        
        return data;
      },
      err => {
        if(axios.isCancel(err)) {
          return Promise.reject(err);
        }
        console.log('错误url： ' + err.config.url);
        console.log("响应拦截器 err ", err);

        if (err.response.status === 403) {
          sessionStorage.removeItem("Authorization");
          sessionStorage.clear();
          let doms = document.getElementsByClassName("el-message")[0];
          //防止弹出多个提示信息
          if (doms == undefined) {
            Message.warning("登录失效，请重新登录");
          }
          router.push("/login");
        }
        return Promise.reject(err.response); // 返回接口返回的错误信息
      }
    );

    // 3、请求处理
    instance(options)
      .then(res => {
        resolve(res);
        return false;
      })
      .catch(error => {
        reject(error);
      });
  });
}
