import { Loading } from "element-ui";

let loading;

export const showLoading = text => {
  loading = Loading.service({
    lock: true,
    text: text ? text : "加载中…",
    background: "rgba(0, 0, 0, 0.7)"
  });
};

export const closeLoading = () => {
  loading.close();
};
