<template>
  <div style="display: flex; flex-direction: row; justify-content: space-between; ">
    <el-select style="max-width: 32%;" v-model="region.provinceId" filterable clearable placeholder="请选择省" @change="updateRegion('province')">
      <el-option v-for="province in provinces" :key="province.provinceId" :label="province.provinceName" :value="province.provinceId"></el-option>
    </el-select>
    <el-select style="max-width: 32%;" v-model="region.cityId" filterable clearable placeholder="请选择市" @change="updateRegion('city')">
      <el-option v-for="city in cities" :key="city.cityId" :label="city.cityName" :value="city.cityId"></el-option>
    </el-select>
    <el-select style="max-width: 32%;" v-model="region.countyId" filterable clearable placeholder="请选择区/县" @change="updateRegion('county')">
      <el-option v-for="county in counties" :key="county.countyId" :label="county.countyName" :value="county.countyId"></el-option>
    </el-select>
  </div>
</template>

<script>

export default {
  // 暴露给其他组件的名称
  name: "cityRegion",
  data() {
    return {
      // 区域ID实体类
      region: {
        provinceId: "",
        cityId: "",
        countyId: ""
      },
    };
  },
  props: {
    provinces: {
      require: false,
      type: Array,
      default: () => []
    },
    cities: {
      require: false,
      type: Array,
      default: () => []
    },
    counties: {
      require: false,
      type: Array,
      default: () => []
    },
  },
  methods: {
    /*
     * @Date 2022/03/11 16:22:57
     * 更改区域
     */
    updateRegion(type) {
      switch(type) {
        case 'province': 
          this.region.cityId = "";
          this.region.countyId = "";
          this.$emit("regionChange", {type, provinceId: this.region.provinceId});
          break;
        case 'city': 
          this.region.countyId = "";
          this.$emit("regionChange", {type, cityId: this.region.cityId});
          break;
        case 'county': 
          this.$emit("regionChange", {type, countyId: this.region.countyId});
          break;
      }
    },
   
  }
};
</script>

<style scoped>
</style>
