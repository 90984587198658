/*
 * @Author: hanson 1130845163@qq.com
 * @Date: 2022-04-12 11:33:12
 * @LastEditors: hanson 1130845163@qq.com
 * @LastEditTime: 2022-08-18 11:48:00
 * @FilePath: \2002181_OTLRWS_FRONTED_refactoring\src\http\api.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import axios from "./ajax.js";

/**
 * @Author KR0132
 * @Date 2022/06/21 14:12:10
 * @Description 获取登录验证码（更新请求接口）
 */
export const getValidateCode = params => {
  return axios({
    url: "/eprdms/auth/v2/login/code",
    method: "get",
    params: params
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 08:54:37
 * @Description 登录接口
 */
export const login = data => {
  return axios({
    url: "/eprdms/auth/v2/login",
    method: "post",
    data: data
  });
};

/**
 * 检查登录(主要用于第三方接进来)
 * TODO:
 */
export const checkLogin = data => {
  return axios({
    url: "eprdms/auth/v2/checkLogin",
    method: "post",
    data: data
  });
};

/**
 * @Author KR0132
 * @Date 2022/02/09 09:40:30
 * @Description 退出登录
 */
export const logout = data => {
  return axios({
    url: "/eprdms/auth/v2/logout",
    method: "post",
    data: data
  });
};

/**
 * @Author KR0132
 * @Date 2022/02/15 16:25:10
 * @Description 获取系统信息
 */
export const getSystemInfo = params => {
  return axios({
    url: "/eprdms/shiro/system/info",
    method: "get",
    params
  });
};

/**
 * @Author KR0288
 * @Date 2022/07/27 11:52:21
 * @Description 获取项目动态路由
 */
export const getWebPermission = params => {
  return axios({
    // baseURL: window.IPConfig.authURL,
    url: '/eprdms/auth/v2/get/front/permission',
    method: 'get',
    params
  })
}

/**
 * @Author KR0132
 * @Date 2022/09/02 20:03:54
 * @Description 获取应用订阅的主题
 */
 export const getRegistryTopics = params => {
  return axios({
    url: '/eprdms/user/get/topic',
    method: 'get',
    params
  })
}

/**
 * @description: 查询字典值
 * @date 2023-08-16 13:48
 */
export const getDictDataByTypeApi = params => {
  return axios({
    url: '/eprdms/dict/get',
    method: 'get',
    params
  })
}
