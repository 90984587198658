/*
 * @Author: hanson 1130845163@qq.com
 * @Date: 2022-05-27 09:03:35
 * @LastEditors: hanson 1130845163@qq.com
 * @LastEditTime: 2022-08-22 19:23:27
 * @FilePath: \2002181_OTLRWS_FRONTED_refactoring\src\utils\js\tool.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

import store from '../../store/index'
/**
 * @Author KR0288
 * @Date 2022/05/27 09:03:52
 * @Description 处理时间格式YYYYMMDD-hhmmss -> YYYY-MM-DD hh:mm:ss
 */
export function handleTimeFormat(time) {
    let timeArray = time?.split('-');
    let leftStr = timeArray && [timeArray[0]?.slice(0, 4), timeArray[0]?.slice(4, 6), timeArray[0]?.slice(6, 8)].join('-');
    let rightStr = timeArray && [timeArray[1]?.slice(0, 2), timeArray[1]?.slice(2, 4), timeArray[1]?.slice(4, 6)].join(':');
    return `${leftStr} ${rightStr}`
}

/**
 * 获取路由信息
 * @param {菜单信息} item
 * @returns 路由信息
 */
export function getRoute(item) {
    let route = {
        name: item.name,
        path: item.path,
        meta: {
            keepAlive: item.keepAlive,
            webElements: item.webElements
        },
    };
    if (item.component) route.component = () => import(`@/views${item.component}`);
    return route;
}


/**
 * 获取路由列表
 *
 * @param {菜单列表} menuList
 * @returns 路由列表
 */
export function getRoutes(menuList) {

    if (!menuList) return [];
    let menuTree = [];
    let routes = [];

    // 1、遍历菜单列表，如果含有children属性就递归遍历
    menuList.forEach(item => {
        menuTree.push(item);
        if (item.component) {
            let route = getRoute(item);
            if (item.children.length !== 0) {
                const children = getRoutes(item.children);
                route.children = children;
            }
            routes.push(route);
        }
    });
    console.log(menuTree);

    // 2、保存菜单树(防止刷新页面后路由变回初始化，造成一片空白)，并且给路由表进行404的补充(按顺序，404最好在最后，因为不是每个vue2.x版本都是强匹配优先)
    store.commit("saveRoutesData", menuTree);
    routes.push({ path: "*", redirect: "/404" });
    return routes;
}