<template>
  <div class="net-container">
    <div class="net-company">{{ netCompany }}:</div>
    <div class="net-icon">
      <ul class="net-power">
        <li :class="netPower >= 1 ? 'net-active' : false"></li>
        <li :class="netPower >= 2 ? 'net-active' : false"></li>
        <li :class="netPower >= 3 ? 'net-active' : false"></li>
        <li :class="netPower >= 4 ? 'net-active' : false"></li>
        <li :class="netPower >= 5 ? 'net-active' : false"></li>
        <div class="net-type">{{ netType }}</div>
      </ul>
    </div>
    <div class="ping">ping:{{ ping }}</div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  data() {
    return {};
  },
  props: ["netPower", "netType", "netCompany", "ping"],
  computed: {
    ...mapState(["currentCtrlRobot"])
  }
};
</script>
<style scoped>
.net-container {
  position: relative;
  width: 9vw;
  display: flex;
  margin-bottom: 0.5vh;
}
.net-company {
  font-size: 1vh;
  width: 4vw;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.net-icon {
  margin: 0;
  padding: 0;
  width: 1.2vw;
  height: 1.2vw;
}
.ping {
  margin-left: 0.2vw;
  font-size: 1vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.net-power {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  position: relative;
}
.net-power > li {
  /* border: 0.05vh solid #00ffbf; */
  display: inline-block;
  width: 0.1vh;
}
.net-power > li:nth-child(1) {
  height: 20%;
}
.net-power > li:nth-child(2) {
  height: 40%;
}
.net-power > li:nth-child(3) {
  height: 60%;
}
.net-power > li:nth-child(4) {
  height: 80%;
}
.net-power > li:nth-child(5) {
  height: 100%;
}
/* .net-active {
  background-color: #00ffbf;
} */
.net-type {
  font-size: 0.5vw;
  position: absolute;
  top: -0.05vw;
  left: -0.2vw;
}
</style>
