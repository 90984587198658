<template>
  <div id="app">
    <TopHeader v-if="isShowHeader" />
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive" />
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"/>
  </div>
</template>
<script>
import TopHeader from "@/components/TopHeader";
import { getLineNames } from "@/http/homeApi.js";
import {beginPushWeatherData} from "@/http/equipmentApi.js"
import config from "@/http/config.js";
import { mapActions, mapState } from "vuex";
import * as appWebsocket from "@/utils/websocket/appWebsocket.js";
import * as robotWebsocket from "@/utils/websocket/robotWebsocket.js";
import * as browser from "@/utils/js/browser";
import {
  getSecretApi
} from "@/http/analysisApi";
import { logoutRobot } from "@/http/professionalControlApi.js";
export default {
  components: {
    TopHeader
  },
  data() {
    return {
      usersocket: null,
      robotsocket: null,
      userWsClient: null, //用户信息推送
      robotWsClient: null, //机器人信息推送
      user_topic_logout: `/user/queue/logout`, //用户信息推送主题
      user_topic_push: `/user/queue/push`, //用户信息推送主题
      user_topic_GPS: `/user/queue/getgps`, //用户信息推送主题
      user_topic_weather_station: `/user/queue/weather/station`, //气象基站数据推送主题
      user_topic_weather: `/user/queue/weather`, //城市天气推送数据
      user_topic_photo: `/user/queue/photo`, //用户信息推送主题
      user_topic_preview_update: `/user/queue/preview/update`, //用户信息推送主题
      robot_topic_connect: `/topic/push`, //机器人上下线推送主题
      robot_topic_push: `/user/queue/push`, //机器人信息推送主题
      robot_topic_login: `/user/queue/login`, //机器人登陆信息推送主题
      robot_topic_restart:'/user/queue/restart',//机器人重启模块
      subscribeId: "",
      lineList: [],
    };
  },

  computed: {
    ...mapState(["userMessage", "currentLoginedRobot", "allRobotList", "visitorId", "isLogin"]),
    userWsURL() {
      return config.userWsURL;
    },
    robotWsURL() {
      return config.ctrlRobotWsURL;
    },

    /*
     * @Date 2022/05/16 15:56:33
     * 是否显示页面头部
     */
    isShowHeader() {
      return (this.$route.path !== '/login') && (this.$route.path !== '/alert') && (this.$route.path !== '/defect');
    }
  },

  mounted() {
    let _that = this;
     browser.getBrowserId();

    // 切换主题
    if (localStorage.getItem("userTheme")) {
      document.body.className = localStorage.getItem("userTheme");
    } else {
      document.body.className = "green";
    }

    // 浏览器窗口关闭断开websocket连接
    var userInfo = JSON.parse(localStorage.getItem("userInfo"));
    var token = sessionStorage.getItem("Authorization");
    let beginTime = 0;
    function onbeforeunload_handler(e) {
       beginTime = new Date().getTime();
       return true;
    }

    function onunload_handler(e) {
      _that.disconnectWebsocket();

      // 1、关闭浏览器
     if( new Date().getTime() - beginTime <=5) {
          let url = config.baseURL + "/eprdms/auth/v2/shutdown"
          let data = new FormData();
          data.append("username", userInfo.userName);
          data.append("token", token);
          navigator.sendBeacon(url, data);
          this,closeWindow();
      // 2、刷新浏览器
      }else {}
      return true;
    }

    window.addEventListener("beforeunload", e => onbeforeunload_handler(e));
    window.addEventListener("unload",  e => onunload_handler(e));

    // 延时开启websocket连接和请求推送气象数据
    setTimeout(() => {
      if(sessionStorage.getItem("Authorization")) {
        _that.connectWebsocket({
          token: sessionStorage.getItem("Authorization"),
          id: _that.visitorId
        });

        // 刷新页面也需要有登录状态才能推送天气
        if(sessionStorage.getItem("Authorization")) {
          _that.beginPushCityWeather(_that.visitorId);
        }
      }
    }, 5000);

    this.getDictDataByType({
      type: "camera_type",
      dataName: "cameraTypeList"
    });
  },

  watch: {
    isLogin: function(newValue) { 
       if(newValue) {
        // 刷新页面也需要有登录状态才能推送天气
        if(sessionStorage.getItem("Authorization")) {
          this.connectWebsocket({
            token: sessionStorage.getItem("Authorization"),
            id: this.visitorId
          });
          this.beginPushCityWeather(this.visitorId);
        }
       }
    }
  },

  methods: {
    ...mapActions(["getDictDataByType"]),

    /**
     * @Author KR0288
     * @Date 2022/06/16 09:16:46
     * @Description 打开websocket连接
     */
    connectWebsocket({
      token,
      id
    }) {
      appWebsocket.initWS({sessionId: "app_" + id});
      robotWebsocket.initWS({sessionId: token});
    },

    disconnectWebsocket() {
      appWebsocket.destroyWS();
      robotWebsocket.destroyWS();
    },

    closeApp(username) {
      let url = config.baseURL + "/eprdms/shiro/logout"
      let data = new FormData();
     
      data.append("username", username);
      navigator.sendBeacon(url, data);
      
    },

    beginPushCityWeather(id) {
      getLineNames().then(res => {
        /*
       * @Date 2022/03/16 16:57:45
       * 请求开启推送气象数据
       */
        if(res.data.length>0) {
          this.$store.commit("saveLineId", res.data[0].lineId);
          let params = {
            curLineId: res.data[0].lineId, 
            websocketUser: id
          };
          beginPushWeatherData(params).then(res => {
            if(res.code==="000000") {
              let city = res.data.city;
              if(city) {
                this.$store.commit("updateCity", city);
              }
              let stationList = res.data.stationList ? res.data.stationList : [];
              this.$store.commit("updateWeatherStationList", stationList);
            }else {
              this.$message.warning(res.data ? res.data : res.mesg);
            }
          })
        }
      }).catch(err => {
        this.$message.error(err.data.mesg);
      })
    },

    closeWindow() {
      let currentLoginRobot = this.allRobotList.find(robot => robot.robotMac === this.currentLoginedRobot.robotMac);
      if (currentLoginRobot) {
        logoutRobot({
          robotMac: currentLoginRobot.robotMac
        }, this.currentLoginedRobot.robotControlToken)
      }
    }
  }
};
</script>
<style lang="scss">
#app {
  width: 100%;
  height: 100%;
}
</style>
