<template>
  <div class="bureau-tree">
    <!-- 使用key可以在需要时自动刷新界面 -->
    <TreeChart :json="homeTreeData" class="tree-class" :key="currentBureau" @click-node="clickNode" />

    <div class="gl_prs_ctn" :style="[contextstyle]">
      <ul class="gl_prs_li">
        <li @click="handleSelect">设置为当前公司</li>
      </ul>
    </div>
    <span style="margin-bottom: 2vh; color: #f00; font-size: 1.2em">提示：选中需要切换的公司，单击鼠标左键，即可弹出切换公司选项</span>
  </div>
</template>

<script>
import TreeChart from "vue-tree-chart";
import blackImg from "../assets/imgs/common/bureau-black.png";
import redImg from "../assets/imgs/common/bureau-red.png";
export default {
  name: "bureauTree",
  components: {
    TreeChart
  },
  props: ["bureauTree", "currentBureau"],

  data() {
    return {
      contextstyle: {
        display: "none",
        right: "0px",
        top: "0px",
        left: "0px",
        bottom: "0px"
      },

      isClickNode: false,
      selectBureau: ""
    };
  },
  created() {
    document.oncontextmenu = () => {
      return true;
    };
    document.addEventListener("click", () => {
      if (!this.isClickNode) {
        this.contextstyle.display = "none";
      } else {
        this.isClickNode = false;
      }
    });
  },
  computed: {
    homeTreeData() {
      this.treeDataToHomeData(this.bureauTree);
      return this.bureauTree[0];
    }
  },
  methods: {
    /**
     * @Author KR0132
     * @Date 2022/01/23 16:28:54
     * @Description 处理点击公司节点事件
     */
    clickNode(node) {
      if (window.event.x + 188 > document.documentElement.clientWidth) {
        this.contextstyle.left = "unset";
        this.contextstyle.right = document.documentElement.clientWidth - window.event.x + "px";
      } else {
        this.contextstyle.left = window.event.x + "px";
      }
      if (window.event.y + 166 > document.documentElement.clientHeight) {
        this.contextstyle.top = "unset";
        this.contextstyle.bottom = document.documentElement.clientHeight - window.event.y + "px";
      } else {
        this.contextstyle.top = window.event.y + "px";
      }
      this.contextstyle.display = "block";
      this.isClickNode = true;
      this.selectBureau = node.logicId;
    },

    /**
     * @Author KR0132
     * @Date 2022/01/23 16:29:16
     * @Description 处理转换数据实体类事件
     */
    treeDataToHomeData(tree) {
      tree.forEach(item => {
        if (this.currentBureau === item.logicId) {
          item.name = item.label + " [当前选中]";
          item.image_url = redImg;
        } else {
          item.name = item.label;
          item.image_url = blackImg;
        }

        if (Object.hasOwnProperty.call(item, "children") && item.children.length > 0) {
          this.treeDataToHomeData(item.children);
        } else if (Object.hasOwnProperty.call(item, "children") && item.children.length == 0) {
          delete item.children;
        }
      });
    },

    /**
     * @Author KR0132
     * @Date 2022/01/23 17:06:24
     * @Description 设置当前公司
     */
    handleSelect() {
      this.$emit("change-bureau", this.selectBureau);
    }
  }
};
</script>

<style lang="scss" scoped>
.bureau-tree {
  overflow: auto;
  margin: auto;
  color: #fff;

  .tree-class {
    margin: auto;
  }

  .gl_prs_ctn {
    width: 188px;
    background: rgb(255, 255, 255);
    color: #000;
    box-shadow: rgba(0, 0, 0, 0.075) 0px 1px 1px inset, rgba(102, 175, 233, 0.6) 0px 0px 8px;
    z-index: 99999;
    position: fixed;
    padding: 10px;
    box-sizing: content-box;
    height: 30px;
  }
  .gl_prs_li {
    padding: unset;
    margin: unset;
  }
  .gl_prs_li > li {
    cursor: pointer;
    list-style: none;
    border-bottom: 1px solid #efefef;
    padding: 7px 10px;
  }
  li:last-child {
    border: unset;
  }
  li:hover {
    background: #ccc;
    color: #fff;
  }
}

/deep/ .node .person {
  z-index: 2;
  width: 12em !important;
  overflow: hidden;
}
</style>
