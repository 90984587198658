

/*
 * @Date 2022/03/22 09:46:55
 * 订阅用户地址，当在这里收到消息，断开webSocket连接，要求用户重新登录
 */
export const FORCE_LOGOUT_TOPIC = `/user/queue/logout`;

/*
 * @Date 2022/03/22 09:48:23
 * 订阅机器在GPS上的位置
 */
export const GPS_TOPIC = `/topic/robot/gps`;

/*
 * @Date 2022/03/22 09:49:23
 * 城市气象数据主题
 */
export const CITY_WEATHER_TOPIC = `/queue/city/weather/`;

/*
 * @Date 2022/03/22 16:13:10
 * 气象基站数据主题
 */
export const WEATHER_STATION_TOPIC = `/queue/meteorology/`;

/*
 * @Date 2022/09/29 08:41:37
 * 压缩文件完成通知主题
 */
export const COMPRESS_FINISH_TOPIC = "/queue/compress/finish/";

/*
 * @Date 2022/03/28 13:49:54
 * 机器人上下线推送主题，不需要动态拼接机器人地址
 */
export const ROBOT_ONLINE_OFFLINE_TOPIC = `/topic/push`;

/*
 * @Date 2022/03/28 13:51:15
 * 机器人信息推送主题，后面需要动态拼接机器人地址
 */
export const ROBOT_MESSAGE_TOPIC = `/topic/push/`;

/*
 * @Date 2022/03/28 13:51:56
 * 机器人登录信息推送主题，后面需要动态拼接机器人地址
 */
export const ROBOT_LOGIN_TOPIC = (token) => {
  return `/user/${token}/login/`
};

/*
 * @Date 2022/03/28 13:53:31
 * 机器人重启模块推送主题，后面需要动态拼接机器人地址
 */
export const ROBOT_RESTART_TOPIC = `/queue/restart/response`;

/*
 * @Date 2022/09/29 08:41:21
 * 机器人GPS位置信息
 */
export const TEST_GPS = `/topic/robot/gps`;