/*
 * @Author: hanson 1130845163@qq.com
 * @Date: 2022-06-06 13:36:55
 * @LastEditors: hanson 1130845163@qq.com
 * @LastEditTime: 2022-06-09 14:32:43
 * @FilePath: \2002181_OTLRWS_FRONTED_refactoring\src\http\config.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
//config.js axios基础配置
export default {
  // 基础url前缀
  baseURL: window.IPConfig.baseURL, //用户系统ip
  userWsURL: window.IPConfig.userWsURL, //用户系统推送地址
  ctrlRobotURL: window.IPConfig.ctrlRobotURL, //机器人控制ip
  ctrlRobotWsURL: window.IPConfig.ctrlRobotWsURL, //机器人推送地址
  h5sURL: window.IPConfig.h5sURL, // h5s视频播放地址
  tokenPrefix: "Bearer",  // 认证token前缀
  // 请求头信息
  headers: {
    "Content-Type": "application/json;charset=UTF-8"
  },
  // 设置超时时间
  timeout: 100000,
  // 携带凭证
  withCredentials: false,
  // 返回数据类型
  responseType: "json",
  // `transformResponse` 在传递给 then/catch 前，允许修改响应数据
  transformResponse: [
    function (data) {
      // 如果响应数据是JSON字符串，将数据转换为JSON对象
      if (data && typeof data == "string") {
        if (data.startsWith("{") && data.endsWith("}")) {
          return JSON.parse(data);
        }
      } else {
        return data;
      }
    }
  ]
};
