import FingerprintJS from "@fingerprintjs/fingerprintjs";
import store from "@/store/index";

/**
* @Author KR0132
* @Date 2021/10/28 16:36:59
* @Description 获取浏览器指纹信息
*/
export async function getBrowserId () {

  // 获取浏览器唯一标识
  const fpPromise = FingerprintJS.load();
  const fp = await fpPromise;
  const result = await fp.get();

  // 保存到vuex
  let visitorId = result.visitorId;
  store.commit("updateVisitorId", visitorId)

  // 直接返回
  return visitorId;
}