
/**
 * @Author KR0132
 * @Path  src\utils\websocket\robotWebsocket.js 
 * @Date 2022/03/28 13:48:35
 * @Description 机器控制相关websocket连接
 */
import store from '@/store/index'
import websocket from "./newWebsocket.js";
import * as websocketTopic from "./websocketTopic.js";
import config from "@/http/config.js";
import Cookie from "js-cookie";
import Vue from "vue";
import {getRegistryTopics} from "@/http/api.js";

/*
 * @Date 2022/03/22 14:19:48
 * websocket 服务地址
 */
var WEBSOCKET_URL = config.baseURL + '/eprdms/websocket/'

/*
 * @Date 2022/03/28 15:47:25
 * 实例化vue
 */
var vm = new Vue();

/*
 * @Date 2022/03/22 14:51:20
 * websocket 连接实例
 */
let robotSocket = null;

/**
 * @description: 该ws连接的唯一标识
 * @date 2023-10-17 16:40
 */
let sessionId = "";

/**
 * @Author KR0132
 * @Date 2022/03/22 14:28:09
 * @Description 初始化 websocket 连接
 */
export function initWS (headers) {
  robotSocket = new websocket(WEBSOCKET_URL, headers);
  sessionId = headers.sessionId;
  robotSocket.connect(() => {

    // 1、订阅机器人上下线主题
    robotSocket.subscribe(websocketTopic.ROBOT_ONLINE_OFFLINE_TOPIC, handleRobotOnlineOffLine);

    // 2、订阅机器重启模块消息主题
    robotSocket.subscribe(websocketTopic.ROBOT_RESTART_TOPIC, handleRobotRestart);

    // 3、测试订阅gps
    // robotSocket.subscribe(websocketTopic.TEST_GPS, handleRobotGpsPos);

    // 4、从后台查询已订阅主题，判断是否需要重新订阅
    getRegistryTopics({session: headers.sessionId}).then(res => {
      console.log(res.data);
      res.data.forEach(topic => {
        if(topic.indexOf("login")>-1) {
          let robotMac = topic.split("/")[topic.split("/").length - 1];
          this.changeSubscribeRobot(robotMac);
        }
      })
    })
  });
}

/**
 * @Author KR0132
 * @Date 2022/03/22 14:46:25
 * @Description 关闭websocket连接
 */
export function destroyWS () {
  if (robotSocket) {
    robotSocket.close();
  }
}

/**
 * @Author KR0288
 * @Date 2022/06/15 17:54:09
 * @Description 初次的订阅主题
 */
export function subscribeTopic (robotMac) {
  if (robotMac && robotSocket) {
    // 1、先取消订阅先前订阅的机器人
    getRegistryTopics({session: sessionId}).then(res => {
      res.data.forEach(topic => {
        if (
          topic.indexOf("login") > -1 || 
          topic.indexOf(websocketTopic.ROBOT_MESSAGE_TOPIC) > -1
        ) {
          robotSocket.unsubscribe(topic);
        }
      })

      // 订阅机器人信息和登录主题
      robotSocket.subscribe(websocketTopic.ROBOT_LOGIN_TOPIC(sessionStorage.getItem("Authorization")) + robotMac, handleRobotLoginLogout);
      robotSocket.subscribe(websocketTopic.ROBOT_MESSAGE_TOPIC + robotMac, handleRobotMessage);
    })
  }
}

/**
 * @Author KR0288
 * @Date 2022/06/15 17:54:40
 * @Description 取消订阅初次连接所订阅的主题
 */
 export function unsubscribe (robotMac) {
  if (robotSocket) {

    // 取消订阅机器人信息和登录成功响应主题
    robotSocket.unsubscribe(websocketTopic.ROBOT_LOGIN_TOPIC(sessionStorage.getItem("Authorization")) + robotMac);
    robotSocket.unsubscribe(websocketTopic.ROBOT_MESSAGE_TOPIC + robotMac);
  }
}

/**
 * @Author KR0132
 * @Date 2022/03/31 11:14:04
 * @Description 切换登录或首次登录机器人，订阅其需要的主题
 */
 export function changeSubscribeRobot (robotMac) {
  if (robotMac && robotSocket) {
    // 订阅机器人信息推送和登录成功响应主题
    subscribeTopic(robotMac)
  }
}

/**
 * @Author KR0288
 * @Date 2022/04/15 11:49:29
 * @Description 处理机器GPS地址
 */
export function handleRobotGpsPos (socketContent) {
  store.commit('updateRobotGpsPos', socketContent)
}

/**
 * @Author KR0132
 * @Date 2022/03/28 14:59:47
 * @Description 处理机器上下线、心跳数据
 */
export function handleRobotOnlineOffLine (socketContent) {
  console.log("on_off_line", socketContent);
  if (socketContent.hasOwnProperty("cmd")) {

    // connect表示机器人上下线
    if (socketContent.cmd == "connect") {
      console.log("上下线通知", socketContent);
      let allRobotList = store.state.allRobotList;

      // 如果上线或下线的机器人是该用户的机器人
      if (
        allRobotList.some(item => {
          return item.robotMac == socketContent.id;
        })
      ) {

        // result:  1表示上线 0表示下线
        if (socketContent.result == 1) {
          let arr = [];
          arr.push(socketContent);
          store.commit("updateOnlineRobot", arr);
        } else if (socketContent.result == 0) {
          let arr = [];
          arr.push(socketContent);

          // 下线则取消订阅所有主题
          unsubscribe(socketContent.id);
          store.commit("updateOnlineRobot", arr);
          if (socketContent.id === store.state.currentLoginedRobot.robotMac) {
            store.commit("loginRobot", "");
            localStorage.removeItem("CtrledRobot");
            Cookie.remove("KR_Robot_Ctrl_token");
            vm.$message({
              message: "机器离线",
              type: "warning"
            });
          }
        }
      }
    } else if (socketContent.cmd == "keepalive") {

      //获取机器状态
      store.commit("saveRobotStatus", socketContent);
    } else if (socketContent.cmd === "robotstate") {
      store.commit("updateRobotWaveUpSleepMsg", socketContent.motorstate)
      socketContent.result === "1" ? 
      vm.$message.success(socketContent.data.desc) :
      vm.$message.error(socketContent.data.desc)
    }
  }
}

/**
 * @Author KR0132
 * @Date 2022/03/28 15:16:06
 * @Description 处理机器人信息推送
 */
export function handleRobotMessage (message) {
  var socketContent = JSON.parse(JSON.stringify(message))
  console.log('----------------------');
  console.log("robotMessage", socketContent);


  var socketContent = JSON.parse(JSON.stringify(message))

  console.log("状态", JSON.stringify(message))
  //KR0176 当result为0 ，表示操作失败，将desc 描述信息显示在操作日志处
  if (socketContent.result == "0") {
    if (store.state.currentLoginedRobot.robotMac === socketContent.id) {
      store.commit("saveDesc", socketContent);
    }
    if (socketContent.cmd == 'version') {
      alert('获取机器版本号失败', {
        title: '失败'
      })
    }
    return;
  }

  // 2、处理正常情况下各类推送信息
  if (socketContent.hasOwnProperty("cmd")) {

    // 如果推送数据中有cmd命令，判断命令的类型
    switch (socketContent.cmd) {

      // 2.1 云台坐标
      case "ptzgetpos": {
        const { abscissa, ordinate } = socketContent.data;
        store.commit("updatePtzPos", {
          abscissa,
          ordinate
        });
        break;
      }

      case "keepalive": {
        // 1、保存机器状态
        store.commit("saveRobotStatus", socketContent);
        
        // 2、更新当前登录机器人所有状态信息
        if (store.state.currentLoginedRobot.robotMac === socketContent.id) {
          store.commit("updateKeepalive", socketContent.data);
        } else {

          // 3、不是所有机器的心跳中都有基站信息
          if (socketContent.data.hasOwnProperty("content")) {
            for (let i = 0; i < socketContent.data.content.length; i++) {
              socketContent.data.content[i].robotMac = socketContent.id;
            }
            store.commit("saveKeepAliveBaseStationMessage", socketContent.data.content);
          }

        }
        break;
      }
      case "charge_station": {
        //太阳能充电推送
        console.log("太阳能充电推送", socketContent);
        socketContent.data.robotMac = socketContent.id;
        store.commit("saveBaseStationMessage", socketContent);
        break;
      }
      case "assnap": {
        store.commit("snapPicture", socketContent);
        break;
      }
      case "irsnap": {
        store.commit("snapPicture", socketContent);
        break;
      }

      // 机器人动作时返回的推送（机器人状态，包括机械臂状态）
      case "robotstate": {
        if (store.state.currentLoginedRobot.robotMac === socketContent.id) {
          store.commit("saveSocketMsg", socketContent);
        }
        break;
      }

      
      case "upgrade":
        {
          if (socketContent.result == 1) {
            vm.$message({
              type: "success",
              message: "升级成功！"
            });
          } else {
            vm.$message({
              type: "warning",
              message: socketContent.data.desc
            });
          }
        }
        break;

      case "sensor": {
        //刷新按钮返回的推送，不包含有用的信息
        if (store.state.currentLoginedRobot.robotMac === socketContent.id) {
          // store.commit("saveSensor", socketContent);
        }
        break;
      }

      /*
       * @Date 2022/07/07 11:56:43
       * 刷新按钮返回 / 初次订阅消息主题的推送信息，data里包含温度坡度信息
       */
      case "sensorstate": {
        if (store.state.currentLoginedRobot.robotMac === socketContent.id) {
          console.log(socketContent.data);
          store.commit("updateSensorstate", socketContent.data);
        }
        break;
      }
      case "logout": {
        //机器人推送来logout后处理
        if (store.state.currentLoginedRobot.robotMac === socketContent.id) {
          //机器登出取消订阅主题
          unsubscribe(socketContent.id);
          store.commit("loginRobot", ""); //清空全局的已登录机器人token
          localStorage.removeItem("CtrledRobot");
          Cookie.remove("KR_Robot_Ctrl_token"); //清空本地机器人token
        }
        break;
      }
      case "version": {
        if (socketContent.result == '1') {

          alert(socketContent.data.version ? socketContent.data.version : "未获取到版本号，请检查图传是否升级!", {
            title: '机器版本'
          })
        }
      }
      case "track": {
        if(socketContent.data && socketContent.resolution) {
          let saveDataObj = {
            position: socketContent.data,
            resolution: socketContent.resolution
          }
          store.commit("saveVideoDrawTag", saveDataObj);
        }
      }
      case "gps": {
        if (socketContent.data) {
          store.commit("updateRobotAddress", socketContent.data)
        }
      }
      default:
        {
          if (store.state.currentLoginedRobot.robotMac === socketContent.id) {
            if (socketContent.result == 2) {
              //没登录
              vm.$message({
                type: "warning",
                message: socketContent.data.desc
              });
              store.commit("loginRobot", "");
              localStorage.removeItem("CtrledRobot");
              Cookie.remove("KR_Robot_Ctrl_token");
            }
          }
        }
        break;
    }
  }
}

/**
 * @Author KR0132
 * @Date 2022/03/28 15:27:01
 * @Description 处理登录机器人
 */
export function handleRobotLoginLogout (loginRes) {
  console.log('----------------------');
  console.log("login",store.state.currentLoginedRobot,  loginRes)
  if (loginRes.success == true) {
    if (loginRes.cmd == "login") {
      let token = loginRes.robotControlToken;
      store.commit("updateCurrentLoginedRobot", loginRes)
      let inFifteenMinutes = new Date(new Date().getTime() + 60 * 60 * 1000);
      Cookie.set("KR_Robot_Ctrl_token", token, {
        expires: inFifteenMinutes
      });
    }
    if (loginRes.cmd == "userlogout") {
      if (store.state.currentLoginedRobot.robotMac === loginRes.id) {
        vm.$message({
          type: "warning",
          message: loginRes.message
        });
        store.commit("loginRobot", ""); //清空全局的已登录机器人token
        localStorage.removeItem("CtrledRobot");
        Cookie.remove("KR_Robot_Ctrl_token"); //清空本地机器人token
      }
    }
  }
}

/**
 * @Author KR0132
 * @Date 2022/03/28 15:27:46
 * @Description 处理重启模块在线状态更新
 */
export function handleRobotRestart (socketContent) {

  switch (socketContent.resultType) {
    case "RST_RESULT_GET_RELAY_OFF":
    case "RST_RESULT_GET_RELAY_ON":
      vm.$busEvent.$emit('RST_RESULT_GET', socketContent)
      break;
    case "RST_RESULT_SET_RELAY_OFF":
    case "RST_RESULT_SET_RELAY_ON":
      vm.$busEvent.$emit('RST_RESULT_GET', socketContent)
      vm.$busEvent.$emit('RST_RESULT_SET', socketContent)
      break;
    case "RESPONSE_WAIT_TIMEOUT":
      vm.$busEvent.$emit('RESPONSE_WAIT_TIMEOUT', socketContent)
      break;
  }

  // 将响应消息放到 vuex
  store.commit("updateSlightRobotRst", socketContent);

}
