/**
 * @Author KR0132
 * @Path  src\utils\websocket\newWebsocket.js 
 * @Date 2022/03/22 14:15:56
 * @Description stomp v6+ 版本处理websocket协议JS
 *              npm install sockjs-client
 *              npm install @stomp/stompjs （注意需要引入最新版本的stomp）
 */
import SockJS from 'sockjs-client';
import { Client } from '@stomp/stompjs';

/**
 * @Author KR0132
 * @Date 2022/03/22 10:06:10
 * @Description 初始化 websocket 连接
 */
const websocket = (wsUrl, headers) => {

    /*
     * @Date 2022/03/22 14:14:27
     * 存放已订阅主题哈希表
     */
    var subscribeMap = new Map();

    /*
     * @Date 2022/03/31 13:52:27
     * websocket是否有效
     */
    var websocketIsActive = false;

    // 1、初始化stomp
    let stompClient = new Client({
        connectHeaders: headers,
        debug: function (str) {
            // debug 日志，调试的时候开启
            // console.log(str);
        },
        reconnectDelay: 10000,  // 掉线重连时间间隔
        heartbreakIncomming: 0,  // 客户端期望4000接收到一次心跳
        heartbreakOutgoing: 5000, // 客户端间隔4000毫秒发送一次心跳数据
    });

    // 2、为STOMP配置SockJS和连接超时时间
    stompClient.webSocketFactory = function () {
        return new SockJS(wsUrl, null, { timeout: 10000 })
    }

    return {
        stompClient: stompClient,
        subscribeMap: subscribeMap,
        // 连接
        connect (callback) {

            // 每条主题连接前
            stompClient.beforeConnect = function (frame) {
                console.log('before connect ...');
            },

            // 连接成功回调，所有订阅主题均需在此回调里面实现
            stompClient.onConnect = function (frame) {
                console.log("connect success...", frame);
                websocketIsActive = true;
                callback(frame);
            }

            // 每条连接后
            stompClient.onDisconnect = function (frame) {
                console.log(frame);
            }

            // 连接错误回调
            stompClient.onStompError = function (frame) {
                console.log('websocket 连接异常!');
                console.log('代理响应错误：' + frame.headers['message']);
                console.log('错误详情：' + frame.body);
            }

            // websocket 关闭后
            stompClient.onWebSocketClose = function (frame) {
                console.log('websocket 连接已断开！');
                websocketIsActive = false;
            }

            // 启动stomp
            stompClient.activate();
        },

        // 关闭
        close () {
            if (stompClient && websocketIsActive) {
                websocketIsActive = false;
                stompClient.deactivate();
                subscribeMap.clear();
                stompClient = null;
            }
        },

        // 发送普通字符串消息
        send (destination, message, headers) {
            if (stompClient) {
                let params = { destination, body: message };
                if (headers) {
                    params.headers = headers;
                }
                stompClient.publish(params);
            }
        },

        // 发送JSON消息
        sendJsonMsg (destination, message, headers) {
            if (stompClient) {
                let params = { destination, body: JSON.stringify(message) };
                if (headers) {
                    params.headers = headers;
                }
                stompClient.publish(params);
            }
        },

        // 发送字节流，要求字节流为 Uint8Array 类型
        sendBinary (destination, message, headers) {
            if (stompClient) {
                let params = { destination, binaryBody: message };
                let finalHeaders = { 'content-type': 'application/octet-stream' }
                if (headers) {
                    headers['content-type'] = 'application/octet-stream';
                    finalHeaders = headers;
                }
                params.headers = finalHeaders;

                stompClient.publish(params);
            }
        },

        /**
         * @Author KR0132
         * @Date 2022/09/05 10:05:02
         * @Description 订阅主题
         * @Param destination: 订阅主题的地址
         * @Param callback: 收到主题消息的处理函数
         * @Param ack: 每收到一条正确的数据回复一个ack
         * @Param nack: 每收到一条错误的数据回复一个nack
         */
        subscribe (destination, callback, ack, nack) {
            if (stompClient) {
                let subscription = stompClient.subscribe(destination, res => {

                    // 根据响应消息类型调用不同的转换方法
                    let messageBody = "";
                    if (res.headers['content-type'] === 'application/octet-stream') {
                        // 处理数据流
                        messageBody = res.body.toString();
                    } else {
                        // 处理JSON
                        messageBody = JSON.parse(res.body)
                    }

                    let sendId = res.headers["sendId"];
                    if(sendId) {
                        res.ack({ack: sendId})
                    }

                    // 执行处理方法
                    callback(messageBody);

                    // // 发送ack数据
                    // if(ack) {
                    //     res.ack();
                    // }
                    // // 发送nack数据
                    // if(nack) {
                    //     res.nack()
                    // }

                    /*
                     * @Date 2022/05/25 14:54:44
                     * 将订阅地址作为ID传到后台
                     */
                }, { id: destination , ack: "client ack"});

                // 新订阅主题，放到哈希表  
                subscribeMap.set(destination, subscription);
            }
        },

        // 取消订阅主题
        unsubscribe (destination) {
            if (stompClient) {
                let subscription = subscribeMap.get(destination);
                if (subscription) {
                    // 取消订阅并将主题从哈希表中移除
                    subscription.unsubscribe("13321");
                    subscribeMap.delete(destination);
                }
            }
        }
    }
}

export default websocket;
