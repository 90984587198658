//设备管理界面接口
import axios from "./ajax.js"; // 导入ajax

/**
 * @Author KR0288
 * @Date 2022/05/18 10:17:24
 * @Description 查询机器人列表
 */
export const getRobots = data => {
  return axios({
    url: "/eprdms/device/robot/get",
    method: "post",
    data
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 10:17:52
 * @Description 获取机器人类型
 */
export const getRobotType = params => {
  return axios({
    url: "/eprdms/dict/robotType",
    method: "get",
    params: params
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 10:19:20
 * @Description 添加机器人
 */
export const addRobot = data => {
  return axios({
    url: `/eprdms/device/robot/add`,
    method: "post",
    data
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 10:19:32
 * @Description 删除机器人
 */
export const delRobot = params => {
  return axios({
    url: `/eprdms/device/robot/delete`,
    method: "put",
    params
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 10:19:58
 * @Description 更新机器人
 */
export const updateRobot = data => {
  return axios({
    url: `/eprdms/device/robot/update`,
    method: "put",
    data
  });
};

// // 获取线路 v2
// export const getLineList = params => {
//   return axios({
//     url: `/eprdms/line/getLineList`,
//     method: "get",
//     params
//   });
// };

/**
 * @Author KR0288
 * @Date 2022/05/18 10:22:54
 * @Description 查询所有线路的名称
 */
export const getAllLineList = params => {
  return axios({
    url: '/eprdms/line/get/simple',
    method: "post",
    params
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 10:28:05
 * @Description 查询公司列表
 */
export const getBureauList = params => {
  return axios({
    url: '/eprdms/bureau/get',
    method: "get",
    params
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 10:28:31
 * @Description 新增摄像头
 */
export const addCamera = data => {
  return axios({
    url: `/eprdms/device/camera/add`,
    method: "post",
    data
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 10:30:44
 * @Description 更新摄像头
 */
export const updateCamera = data => {
  return axios({
    url: `/eprdms/device/camera/update`,
    method: "post",
    data
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 10:30:49
 * @Description 删除摄像头
 */
export const delCamera = params => {
  return axios({
    url: `/eprdms/device/camera/delete`,
    method: "delete",
    params
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 10:31:16
 * @Description 分页查询摄像头
 */
export const getCamera = params => {
  return axios({
    url: `/eprdms/device/camera/get`,
    method: "get",
    params
  });
};

/**
 * @description: 根据机器人id获取摄像头信息
 * @date 2023-07-20 16:02
 */
export const getCameraListByRobotIdApi = params => {
  return axios({
    url: "/eprdms/device/camera/get/byRobotId",
    method: "get",
    params
  })
}

// 模糊查询机器人
//TODO:
export const getRobotFuzzy = params => {
  return axios({
    url: `/eprdms/device/robot/get/by/condition`,
    method: "get",
    params
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 10:36:27
 * @Description 模糊查询摄像头
 */
export const getCameraFuzzy = params => {
  return axios({
    url: `/eprdms/device/camera/get/by/condition`,
    method: "get",
    params
  });
};

/**
 * @Author KR0288
 * @Date 2022/06/20 09:50:26
 * @Description 登录视频服务
 */
export const loginH5sStream = params =>  {
  return axios({
    url: window.IPConfig.h5sApiURL + '/api/v1/Login',
    methods: "get",
    params
  })
};


/**
 * @Author KR0288
 * @Date 2022/06/21 09:18:43
 * @Description 保活session
 */
export const keepAliveSession = params => {
  return axios({
    url: window.IPConfig.h5sApiURL + '/api/v1/Keepalive',
    methods: 'get',
    params
  })
}


// h5s 配置摄像头-添加
export const h5sAddCamera = params => {
  return axios({
    url: window.IPConfig.h5sApiURL + `//api/v1/AddSrcRTSP`,
    method: "get",
    params
  });
};

// h5s 配置摄像头-删除
export const h5sDelCamera = params => {
  return axios({
    url: window.IPConfig.h5sApiURL + `//api/v1/DelSrc`,
    method: "get",
    params
  });
};

// 查询控制记录
// TODO://
export const getControlRecords = params => {
  return axios({
    url: `/eprdms/device/getControlRecords`,
    method: "get",
    params
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 10:45:00
 * @Description 查询组网充电基站告警记录
 */
export const getAlarmsRecords = params => {
  return axios({
    url: `/eprdms/device/alarm/record/get`,
    method: "get",
    params
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 10:52:13
 * @Description 查询重启操作记录
 */
export const getOperation = params => {
  return axios({
    url: `/eprdms/device/restart/get/operation`,
    method: "post",
    data: params
  });
};

//查询模块在线记录
// TODO://
export const getRecord = params => {
  return axios({
    url: `/eprdms/device/restart/get/record`,
    method: "post",
    data: params
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 10:54:05
 * @Description 查询25KG重启模块
 */
export const get25kgRestartModule = data => {
  return axios({
    url: `/eprdms/device/restart/config/get`,
    method: "post",
    data
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 10:55:05
 * @Description 新增25KG重启模块
 */
export const add25kgRestartModule = data => {
  return axios({
    url: `/eprdms/device/restart/config/add`,
    method: "post",
    data
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 11:32:24
 * @Description 更新25KG重启模块
 */
export const update25kgRestartModule = data => {
  return axios({
    url: `/eprdms/device/restart/config/update`,
    method: "post",
    data
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 11:33:02
 * @Description 删除25KG重启模块
 */
export const delete25kgRestartModule = params => {
  return axios({
    url: `/eprdms/device/restart/config/delete`,
    method: "get",
    params
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 11:33:40
 * @Description 更新重启模块绑定的邮箱
 */
export const update25kgRestartModuleEmails = data => {
  return axios({
    url: `/eprdms/device/restart/config/update/email`,
    method: "post",
    data
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 11:34:06
 * @Description 查询25KG重启模块可关联的机器
 */
export const get25kgRestartModuleRobots = params => {
  return axios({
    url: `/eprdms/device/restart/config/get/available/robot`,
    method: "get",
    params
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 11:37:00
 * @Description 查询机器在状态字典值
 */
export const getRobotOnlineStatusDict = params => {
  return axios({
    url: `/eprdms/dict/robot/status`,
    method: "get",
    params
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 11:37:59
 * @Description 查询25KG重启模块操作日志
 */
export const get24kgRestartModuleRecord = data => {
  return axios({
    url: `/eprdms/device/restart/get/operations`,
    method: "post",
    data
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 11:39:24
 * @Description 查询气象基站
 */
export const getWeatherStations = data => {
  return axios({
    url: `/eprdms/device/weather/get`,
    method: "post",
    data
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 11:39:30
 * @Description 查询指定线路的气象基站
 */
export const getLineWeatherStations = data => {
  return axios({
    url: `/eprdms/device/weather/get/list`,
    method: "post",
    data
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 11:39:49
 * @Description 新增气象基站
 */
export const addWeatherStation = data => {
  return axios({
    url: `/eprdms/device/weather/add`,
    method: "post",
    data
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 11:41:18
 * @Description 更新气象基站
 */
export const updateWeatherStation = data => {
  return axios({
    url: `/eprdms/device/weather/update`,
    method: "post",
    data
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 11:41:37
 * @Description 删除气象基站
 */
export const deleteWeatherStation = params => {
  return axios({
    url: `/eprdms/device/weather/delete`,
    method: "delete",
    params
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 11:42:29
 * @Description 用户查询气象推送监听列表
 */
export const getLineWeatherPushConfig = params => {
  return axios({
    url: `/eprdms/weather/push/get`,
    method: "get",
    params
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 11:43:11
 * @Description 查询用户正在订阅的气象基站列表
 */
export const getSubscribingWeathers = params => {
  return axios({
    url: `/eprdms/weather/push/get/subscribing`,
    method: "get",
    params
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 11:44:45
 * @Description 更新气象推送监听列表
 */
export const updateSubscribeWeatherStations = data => {
  return axios({
    url: `/eprdms/weather/push/update/station`,
    method: "post",
    data
  });
};


/**
 * @Author KR0288
 * @Date 2022/05/18 11:45:16
 * @Description 用户更新推送的城市气象
 */
export const updateSubscribeCity = data => {
  return axios({
    url: `/eprdms/weather/push/update/city`,
    method: "post",
    data
  });
}

/*
 * @Date 2022/03/14 14:24:52
 * 开始向用户推送气象数据
 */
export const beginPushWeatherData = params => {
  return axios({
    url: `/eprdms/weather/push/begin`,
    method: "get",
    params
  });
};

/**
 * @Author KR0288
 * @Date 2022/06/22 11:52:36
 * @Description 暂停向用户推送气象数据
 */
export const stopPushWeatherData = params => {
  return axios({
    url: `/eprdms/weather/push/stop`,
    method: "get",
    params
  })
}

/*
* @Date 2022/03/14 14:24:52
* 更新线路的气象推送配置
*/
export const getRebootRecords = params => {
  return axios({
    url: `/eprdms/weather/begin/push`,
    method: "post",
    params
  });
}


/**
 * @Author KR0288
 * @Date 2022/05/20 14:49:52
 * @Description 根据线路查询 / 查询全部线路的告警表格信息
 */
export const getWarnNoticeTableData = params => {
  return axios({
    url: '/eprdms/device/alarm/contact/get',
    method: 'get',
    params
  })
}

/**
 * @Author KR0288
 * @Date 2022/05/20 16:23:49
 * @Description 新增联系人
 */
export const addContacts = data => {
  return axios({
    url: '/eprdms/device/alarm/contact/add',
    method: 'post',
    data
  })
}

/**
 * @Author KR0288
 * @Date 2022/05/20 16:25:26
 * @Description 重置密码
 */
export const updatePassword = data => {
  return axios({
    url: '/eprdms/device/alarm/contact/update/password',
    method: 'post',
    data
  })
}

/**
 * @Author KR0288
 * @Date 2022/05/20 16:26:23
 * @Description 更新联系人信息
 */
export const updateContacts = data => {
  return axios({
    url: '/eprdms/device/alarm/contact/update',
    method: 'post',
    data
  })
}

/**
 * @Author KR0288
 * @Date 2022/05/20 16:26:23
 * @Description 删除联系人信息
 */
export const deleteContacts = params => {
  return axios({
    url: '/eprdms/device/alarm/contact/delete',
    method: 'get',
    params
  })
}



