
import store from '@/store/index'
import websocket from "./newWebsocket.js";
import * as websocketTopic from "./websocketTopic.js";
import config from "@/http/config.js";


/*
  * @Date 2022/03/22 14:19:48
  * websocket 服务地址
  */
var WEBSOCKET_URL = config.baseURL + '/eprdms/websocket/'

/*
 * @Date 2022/03/22 14:51:20
 * websocket 连接实例
 */
let appSocket = null;

/**
 * @Author KR0132
 * @Date 2022/03/22 14:28:09
 * @Description 初始化 websocket 连接
 */
export function initWS (headers) {
  appSocket = new websocket(WEBSOCKET_URL, headers);
  appSocket.connect(() => {

    // 1、订阅强制退出登录主题
    appSocket.subscribe(websocketTopic.FORCE_LOGOUT_TOPIC, handleForceLogout);

    // 2、订阅城市天气数据
    handleChangeCity();

    // 3、订阅气象基站数据
    handleChangeWeatherStations();

    // 4、订阅压缩完成主题
    appSocket.subscribe(websocketTopic.COMPRESS_FINISH_TOPIC, handleCompressFileFinish);

  });

}

/**
 * @Author KR0132
 * @Date 2022/03/22 14:46:25
 * @Description 关闭websocket连接
 */
export function destroyWS () {
  if (appSocket) {
    appSocket.close();
  }
}

/**
 * @Author KR0132
 * @Date 2022/03/22 14:55:30
 * @Description 取消订阅消息主题
 */
export function unsubscribeWS (destination) {
  if (appSocket) {
    appSocket.unsubscribe(destination);
  }
}

/**
 * @Author KR0132
 * @Date 2022/03/22 14:34:22
 * @Description 处理强制退出登录事件
 */
export function handleForceLogout (message) {

  store.commit('setSentNotice', message);
  // 主动断开 websocket 连接
  appSocket.close();
}

/**
 * @Author KR0132
 * @Date 2022/03/22 11:33:30
 * @Description 处理城市天气事件
 */
export function handleReceiveCityWeatherData (message) {
  store.commit("updateCityWeather", message);
}

/**
 * @Author KR0132
 * @Date 2022/03/22 16:16:55
 * @Description 处理气象基站天气数据
 */
export function handleReceiveWeatherStationData (message) {
  store.commit("updateWeatherStationDataList", message);
}

/**
 * @Author KR0132
 * @Date 2022/09/29 08:44:38
 * @Description 后台压缩文件完成通知
 */
export function handleCompressFileFinish (message) {
  if(message) {
    store.commit("saveCompressFile", message.file);
  }
}

/**
 * @Author KR0132
 * @Date 2022/03/22 16:05:49
 * @Description 处理变更城市
 */
export function handleChangeCity () {

  if (appSocket) {
    // 1、先取消订阅先前订阅的城市
    let preTopics = [...appSocket.subscribeMap.keys()].filter(k => { return k.indexOf(websocketTopic.CITY_WEATHER_TOPIC) > -1 });
    if (preTopics && preTopics.length > 0) {
      preTopics.forEach(topic => {
        appSocket.unsubscribe(topic);
      });
    }

    // 2、订阅新的城市
    let city = store.state.city;
    if (city) {
      appSocket.subscribe(websocketTopic.CITY_WEATHER_TOPIC + city, handleReceiveCityWeatherData);
    }

  }
}

/**
 * @Author KR0132
 * @Date 2022/03/22 16:11:15
 * @Description 处理变更订阅的气象基站
 */
export function handleChangeWeatherStations () {

  if (appSocket) {
    // 1、先取消订阅先前的气象基站
    let preTopics = [...appSocket.subscribeMap.keys()].filter(k => { return k.indexOf(websocketTopic.WEATHER_STATION_TOPIC) > -1 });
    if (preTopics && preTopics.length > 0) {
      preTopics.forEach(topic => {
        appSocket.unsubscribe(topic);
      });
    }

    // 2、订阅新的地址
    let stations = store.state.weatherStationList;
    if (stations && stations.length > 0) {
      stations.forEach(station => {
        appSocket.subscribe(websocketTopic.WEATHER_STATION_TOPIC + station.weatherStationMac, handleReceiveWeatherStationData)
      })
    }
  }
}

