/*
 * @Author: hanson 1130845163@qq.com
 * @Date: 2022-04-12 11:33:12
 * @LastEditors: hanson 1130845163@qq.com
 * @LastEditTime: 2022-09-30 10:43:00
 * @FilePath: \2002181_OTLRWS_FRONTED_refactoring\src\main.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import "normalize.css";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "./scss/_style.scss";

import Viewer from "v-viewer";
import "viewerjs/dist/viewer.css";

import Directives from "./directive/index.js";
Vue.use(Directives);

Vue.prototype.$busEvent = new Vue();

Vue.use(Viewer);
Viewer.setDefaults({
  Options: {
    inline: false,
    button: false,
    navbar: false,
    title: true,
    toolbar: true,
    tooltip: true,
    movable: true,
    zoomable: true,
    rotatable: true,
    scalable: true,
    transition: true,
    fullscreen: true,
    keyboard: true,
    url: "data-source"
  }
});

Vue.use(ElementUI);
Vue.config.productionTip = false;

export let el = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");

Vue.prototype.$hasElement = function(element) {
  let elementList = el.$route.meta.webElements;
  return Object.keys(elementList).some(item => {
    if(elementList[item] === element) {
      return true
    }
  })
}