<template>
  <div class="main-header inspection-page">
    <header class="top-header">
      <div class="left">
        <div class="weather" v-if="$route.path != '/professionalControl' && $route.path != '/simpleControl'" @click="showWeatherDialog" style="cursor:pointer;">
           <weather-top/>
        </div>
        <div class="netIcon" style="display: flex;margin-left: 1.5vw;" v-else>
          <netIcon :netType="keepalive.sim1state" :netPower="keepalive.sim1rsrp" :ping="keepalive.sim1delay" netCompany="通道1质量" />
          <netIcon :netType="keepalive.sim2state" :netPower="keepalive.sim2rsrp" :ping="keepalive.sim2delay" netCompany="通道2质量" />
        </div>
      </div>
      <div class="middle title">
        输电线路巡检机器人综合数据系统
      </div>
      <div class="right">
        <div class="user">
          <span class="user-icon"></span>
          <el-dropdown class="my-dropdown-menu" @command="handleCommand">
            <span class="el-dropdown-link" style="color:#fff">
              {{ userMessage.userName }}
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-for="(item, index) in userMenuList" :key="index" :command="item.id">{{ item.value }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </header>
    <!-- 用户信息弹出框 -->
    <el-dialog class="my-dialog2" title="用户信息" :close-on-click-modal="false" :visible.sync="userDetailDialogVisible" width="50vw" top="10vh">
      <div class="user-message-description">
        <el-descriptions class="margin-top" :column="2" size="medium" border>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-user"></i>
              用户名
            </template>
            {{ userInfo.userName }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-mobile-phone"></i>
              联系电话
            </template>
            {{ userInfo.phoneNumber }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-map-location"></i>
              行政区域
            </template>
            {{ userInfo.region }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-office-building"></i>
              所属公司
            </template>
            {{ userInfo.bureauName }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-message"></i>
              邮箱地址
            </template>
            {{ userInfo.emailAddress }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-time"></i>
              登录时间
            </template>
            {{ userInfo.lastLogin }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-tickets"></i>
              备注信息
            </template>
            {{ userInfo.remark }}
          </el-descriptions-item>
          <template slot="extra">
            <el-button class="kr-normal-button green-button-actived" size="small" @click="updateUser">修改用户</el-button>
            <el-button class="kr-normal-button green-button-actived" size="small" @click="resetPassword">修改密码</el-button>
          </template>
        </el-descriptions>
      </div>
    </el-dialog>
    <!-- 修改用户信息弹窗 -->
    <el-dialog class="my-dialog" title="修改用户信息" :close-on-click-modal="false" width="30vw" :visible.sync="updateUserDialogVisible" label-width="6em">
      <el-form :inline="true" :model="userForm" :rules="userFromRules" ref="userFormRef">
        <el-form-item label="用户姓名">
          <el-input v-model="userForm.userName" placeholder="请输入用户名" />
        </el-form-item>
        <el-form-item label="所属公司">
          <el-select class="normal-select" v-model="userForm.bureauId" placeholder="请选择所属公司">
            <el-option v-for="bureau in bureauList" :key="bureau.id" :label="bureau.bureauName" :value="bureau.bureauId"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="联系电话">
          <el-input v-model="userForm.phoneNumber" placeholder="请输入联系电话" />
        </el-form-item>
        <el-form-item label="邮箱地址">
          <el-input v-model="userForm.emailAddress" placeholder="请输入邮箱地址" />
        </el-form-item>
        <el-form-item label="备注信息">
          <el-input class="remark-class" v-model="userForm.remark" type="textarea" :autosize="{ minRows: 2, maxRows: 4 }" style="width: 23vw" placeholder="请输入备注信息" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button class="cancel-button" @click="updateUserDialogVisible = false">取 消</el-button>
        <el-button class="save-button" @click="commitUpdateUser">确 认</el-button>
      </span>
    </el-dialog>
    <!-- 重置用户密码弹窗 -->
    <el-dialog class="my-dialog reset-password-dialog" :title="'修改密码'" :close-on-click-modal="false" :visible.sync="resetUserPasswordVisible" 
    @close="closeResetUserPasswordDialog()">
      <el-form label-position="left" :model="resetPasswordForm" ref="resetPasswordFormRef" :rules="resetPasswordRules" label-width="6em">
        <el-form-item label="用户姓名" prop="userName">
          <el-input v-model="resetPasswordForm.userName" placeholder="请输入用户名" />
        </el-form-item>
        <el-form-item label="用户密码" prop="password">
          <el-input v-model="resetPasswordForm.password" placeholder="请输入用户密码" show-password />
        </el-form-item>
        <el-form-item label="验  证  码" prop="code" class="code-position">
          <el-input v-model="resetPasswordForm.code" @keyup.native.enter="loginConfirm('loginForm')" placeholder="请输入验证码"></el-input>
          <el-image :src="codeImg" alt="验证码" @click="getImgCode" style="width: 6vw; height: 3vh; top: 1vh; margin-left: 1vh" />
        </el-form-item>
        <el-form-item label="新 密 码" prop="newPassword">
          <el-input v-model="resetPasswordForm.newPassword" placeholder="请输入新密码" show-password />
        </el-form-item>
        <el-form-item label="确认密码" prop="checkPassword">
          <el-input v-model="resetPasswordForm.checkPassword" placeholder="请重新输入新密码" show-password />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button class="cancel-button" @click="closeResetUserPasswordDialog()">取 消</el-button>
        <el-button class="save-button" @click="resetUserPassword()">确 认</el-button>
      </span>
    </el-dialog>

    <!-- 切换皮肤弹出框 -->
    <el-dialog class="my-dialog" title="切换皮肤" :close-on-click-modal="false" :visible.sync="changeSkinDialogVisible" width="25%" top="25vh">
      <div class="change-skin">
        <div class="skin-item">
          <div class="label">用户名：</div>
          <div class="value">{{ userMessage.userName }}</div>
        </div>
        <!-- <div class="user-item">
          <div class="label">所属供电局：</div>
          <div class="value">广东省佛山市顺德区供电局</div>
        </div> -->
        <div class="skin-item">
          <div class="label">主题：</div>
          <div>
            <el-radio-group v-model="themeName">
              <el-radio label="green">绿色主题</el-radio>
              <el-radio label="blue">蓝色主题</el-radio>
              <!-- <el-radio label="white">白色主题</el-radio> -->
            </el-radio-group>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="cancel-button" @click="cancelTheme">取 消</el-button>
        <el-button class="save-button" type="primary" @click="saveTheme">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 切换公司弹出框 -->
    <el-dialog class="my-dialog2" title="切换公司" :close-on-click-modal="false" :visible.sync="changeBureauDialogVisible" width="50vw" top="10vh">
      <bureau-tree-chart :bureauTree="bureauTree" :currentBureau="currentBureauId" @change-bureau="handleChangeBureau" />
    </el-dialog>
    <!-- 系统信息对话框 -->
    <el-dialog class="my-dialog" title="系统信息" :close-on-click-modal="false" :visible.sync="systemInfoDialogVisible" width="30%" top="25vh">
      <div style="color: #FFF">
        <el-row v-for="(info, index) in systemInfo" :key="index" style="margin-bottom: 2vh">
          <el-col :span="24">
            <span>{{ info }}</span>
          </el-col>
        </el-row>
      </div>
      <span slot="footer" class="dialog-footer" style="color: #FFF">
        <span style="color: #FFF; font-size: 14px">
          版权所属:
          <span v-html="'\u00a0\u00a0'"></span>
          <el-link href="http://www.keystarirobot.com" type="primary">广东科凯达智能机器人有限公司</el-link>
          <span v-html="'\u00a0\u00a0'"></span>
          <el-link href="https://beian.miit.gov.cn/" type="primary">粤ICP备19069665号</el-link>
        </span>
      </span>
    </el-dialog>

   <!-- 配置监听的气象基站 -->
   <el-dialog class="my-dialog2" title="配置城市天气" :close-on-click-modal="false" :visible.sync="westherConfigDialogVisible" width="30vw" top="20vh" @close="handleCloseDialog('listenLineFormRef')">
       <el-form  :model="listenLineForm"  ref="listenLineFormRef" class="demo-form-inline" label-width="80px">
         <el-form-item label="当前线路" prop="lineId">
            <el-select class="normal-select" v-model="listenLineForm.lineId" disabled placeholder="请选择巡检线路" @change="handleChangeLine">
              <el-option v-for="(line, index) in lineList" :key="index" :label="line.lineName" :value="line.lineId"/>
            </el-select>
          </el-form-item>
          <el-form-item label="所在城市">
           <city-region ref="cityRegionRef" :provinces="provinces" :cities="cities" :counties="counties" @regionChange="handeRegionChange"/>
          </el-form-item>
       </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button class="cancel-button" @click="westherConfigDialogVisible = false">取 消</el-button>
        <el-button class="save-button" @click="updateConfigWeatherMessage">确 认</el-button>
      </span>
    </el-dialog>


    <!-- 帮助文档弹出框 -->
    <el-dialog class="task-template-dialog my-dialog" :close-on-click-modal="false" :visible.sync="helperDialogVisible" height="40vh">
      <span slot="title" class="dialog-footer">
        <el-upload
          class="upload-demo"
          :action='`${config.baseURL}/eprdms/document/uploadFile`'
          :headers="{ Authorization: Authorization }"
          multiple
          :limit="3"
          :on-progress="uploadProgress"
          :on-success="uploadSuccess"
          :on-error="uploadError"
          :before-upload="beforeUpload"
          :show-file-list="false">
          <el-button class="kr-normal-button" size="small" type="primary">上传文件</el-button>
        </el-upload>
      </span>
      <el-table :data="helperFileList" border style="width: 48vw" height="38vh">
        <el-table-column prop="fileName" label="文件名称" align="center" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="fileSize" label="大小" align="center">
          <template slot-scope="scope">
            <div>{{(scope.row.fileSize/1024/1024).toFixed(2)}}M</div>
          </template>
        </el-table-column>
        <el-table-column prop="creatorName" label="上传者" align="center" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="createTime" label="上传时间" align="center" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button class="table-btn"
              size="small" icon="el-icon-download"  title="下载" type="text"
              @click="handleDown(scope.row)"></el-button>
            <el-button
              size="small" icon="el-icon-delete"  title="删除" type="text"
              @click="handleDelete(scope.row)"></el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>

  </div>
</template>
<script>
import {
  getUserInfo,
  getBureauList,
  getBureauTree,
  currentBureau,
  switchBureau,
  updateUserInfo,
  resetUserPassword,
   getAllFiles, 
   deleteFile, 
   downloadFile,
   updateWeatherMessageConfig,
   getProvinces,
   getCities,
   getCounties
} from "@/http/homeApi.js";
import {getLineWeatherPushConfig, updateSubscribeCity, stopPushCityWeather} from "@/http/equipmentApi.js"
import { getValidateCode, logout, getSystemInfo } from "../http/api.js";
import { mapMutations, mapState } from "vuex";
import netIcon from "@/components/netIcon.vue";
import BureauTreeChart from "./BureauTreeChart.vue";
import weatherTop from "@/components/common/weatherTop.vue"; 
import cityRegion from "@/components/cityRegion.vue"
import config from "@/http/config.js";
import { showLoading, closeLoading } from "@/utils/js/Loading.js";
import { getLineNames } from "@/http/homeApi.js";
import * as appWebsocket from "@/utils/websocket/appWebsocket.js";
import * as AesUtil from "@/utils/js/AesUtil.js"

export default {
  name: "TopHeader",
  components: {
    netIcon,
    BureauTreeChart,
    weatherTop,
    cityRegion
  },
  computed: {
    ...mapState(["userMessage", "keepalive", "weather", "lineId", "visitorId", "Secret", "encryptIv"]),
  },
  data() {
    /**
     * @Author KR0132
     * @Date 2022/02/07 10:13:56
     * @Description 校验密码合法性
     */
    var validatePass = (rule, value, callback) => {
      let pattern = "^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{8,16}$";

      if (value === "") {
        callback(new Error("请输入密码！"));
      } else if (value.search(pattern) === -1) {
        callback(new Error("密码必须包含大小写字母和数字的组合，可以使用特殊字符，长度在8-16"));
      }
      callback();
    };

    /**
     * @Author KR0132
     * @Date 2022/02/07 10:22:44
     * @Description 校验输入密码是否相同
     */
    var checkPass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码！"));
      } else if (value != this.resetPasswordForm.newPassword) {
        callback(new Error("两次输入密码不一致！"));
      }
      callback();
    };

    return {
      showWeatherDrawer: false,//是否展示顶部天气
      drawerSize:'55%',
      systemInfoDialogVisible: false,
      changeSkinDialogVisible: false,
      changeBureauDialogVisible: false,
      userDetailDialogVisible: false,
      settingDialogVisible: false,
      updateUserDialogVisible: false,
      resetUserPasswordVisible: false,
      helperDialogVisible:false,
      westherConfigDialogVisible: false,
      userInfo: "",
      bureauList: [],
      userForm: {
        userName: "",
        bureauId: "",
        phoneNumber: "",
        emailAddress: "",
        remark: ""
      },
      userFromRules: {
        userName: [{ required: true, message: "用户名不能为空！", trigger: "blur" }],
        bureauId: [{ required: true, message: "请选择用户所属公司！", trigger: "blur" }],
        phoneNumber: [{ required: true, message: "联系电话不能为空！", trigger: "blur" }],
        emailAddress: [{ required: true, message: "邮件地址不能为空！", trigger: "blur" }]
      },
      codeImg: "",
      resetPasswordForm: {
        username: "",
        password: "",
        newPassword: "",
        checkPassword: "",
        code: ""
      },
      resetPasswordRules: {
        username: [{ required: true, message: "用户名不能为空！", trigger: "blur" }],
        password: [{ required: true, message: "原始密码不能为空！", trigger: "blur" }],
        newPassword: [{ validator: validatePass, trigger: "blur" }],
        checkPassword: [{ validator: checkPass, trigger: "blur" }],
        code: [{ required: true, message: "验证码不能为空！", trigger: "blur" }],
      },
      themeName: "green", //主题名称
      userMenuList: [
        { id: 1, value: "账号信息" },
        // { id: 2, value: "切换皮肤" },    // 暂无切换皮肤功能
        { id: 3, value: "切换公司" },
        { id: 4, value: "帮助文档" },
        { id: 5, value: "系统信息" },
        { id: -1, value: "退出登录" }
      ],
      currentBureauId: "",
      bureauTree: [],
      systemInfo: [], // 系统信息
      helperFileList:[],
      config: config,
      Authorization: sessionStorage.getItem("Authorization"),
      listenLineForm: {
        lineId: "",
        provinceId: "",
        cityId: "",
        countyId: ""
      },
      lineList: [],
      provinces: [],
      cities: [],
      counties: [],

    };
  },
  watch: {
    weather: {
      deep: true
    }
  },
  methods: {
    ...mapMutations(["updateIsLogin"]),

    /**
     * @Author KR0288
     * @Date 2022/06/22 13:45:38
     * @Description 关闭websocket
     */
    disconnectWebsocket() {
      appWebsocket.destroyWS();
    },

    /*
     * @Date 2022/03/14 16:24:45
     * 处理关闭弹窗事件
     */
    handleCloseDialog(formRef) {

      if(formRef.indexOf("listenLineFormRef")>-1) {
        this.listenLineForm.provinceId = "";
        this.listenLineForm.cityId = "";
        this.listenLineForm.countyId = "";

        this.$refs["cityRegionRef"].region = {provinceId: "", cityId: "", countyId: ""};
      }
    },

    handleChangeBureau(bureau) {
      this.currentBureauId = bureau;
      switchBureau({ bureauId: this.currentBureauId }).then(res => {
        if (res.code === "000000") {
          this.$message({ message: res.data, type: "success" });
        } else {
          this.$message.error(res.data);
        }
      });
    },

    /**
     * @Author KR0288
     * @Date 2022/06/22 13:53:25
     * @Description 退出登录
     */
    logoutSystem(query) {
      let params = {username: this.userMessage.userName};
      if (query) {
        this.$confirm("是否退出登录?", "提示", {
          confirmButtonText: "退出",
          cancelButtonText: "取消",
          type: "warning"
        }).then(() => {
          logout(params).then(res => {
            this.$message.success("请求退出系统成功，准备退出！")
          }).catch(err => {
            this.$message.success("强制退出！")
          // 无论如何都要跳转到登录页面
          }).finally(() => {
            sessionStorage.clear();
            setTimeout(() => {
              this.$router.push({ path: "login" });
              this.$message({ message: "您已退出系统！", type: "success" });
              window.location.reload(true);
            }, 1000);
          })
        }).catch(() => {
          this.$message({
            type: "info",
            message: "取消退出登录"
          });
        });
      } else {
        logout(params).then(res => {
          this.$message.success("请求退出系统成功，准备退出！")
        }).catch(err => {
          this.$message.success("强制退出！")
        // 无论如何都要跳转到登录页面
        }).finally(() => {
          sessionStorage.clear();
          setTimeout(() => {
            this.$router.push({ path: "login" });
            this.$message({ message: "您已退出系统！", type: "success" });
            window.location.reload(true);
          }, 1000);
        })
      }
    },
    handleCommand(cmd) {
      switch (cmd) {
        case 1:
          // 账号信息
          this.userDetailDialogVisible = true;
          this.getCurrentUserInfo();
          break;
        case 2:
          // 切换皮肤
          this.changeSkinDialogVisible = true;
          break;
        case 3:
          // 切换公司
          this.changeBureauDialogVisible = true;
          this.$nextTick(() => {
            getBureauTree().then(res => {
              this.bureauTree = res.data;
            });
            currentBureau().then(res => {
              if (res.code === "000000") {
                this.currentBureauId = res.data.bureauId;
              }
            });
          });
          break;
        case 4:
            // 帮组文档
            this.openHelper();
          break;
        case 5:
          // 查看系统信息
          this.systemInfoDialogVisible = true;
          getSystemInfo().then(res => {
            this.systemInfo = res.data;
          });
          break;
        case -1:
          // 退出登录
          this.logoutSystem(true);
          break;
        default:
      }
      console.log(cmd);
    },
    cancelTheme() {
      if (localStorage.getItem("userTheme")) {
        document.body.className = localStorage.getItem("userTheme");
        this.themeName = localStorage.getItem("userTheme");
      } else {
        document.body.className = "green";
        this.themeName = "green";
      }
      this.changeSkinDialogVisible = false;
    },
    saveTheme() {
      localStorage.setItem("userTheme", this.themeName);
      document.body.className = localStorage.getItem("userTheme");
      this.changeSkinDialogVisible = false;
      window.location.reload();
    },
    loginConfirm(form) {
      console.log(form);
    },

    /**
     * @Author KR0132
     * @Date 2022/02/08 15:29:46
     * @Description 获取图形验证码
     */
    getImgCode() {
      getValidateCode().then(res => {
        this.codeImg = window.URL.createObjectURL(res);
      });
    },
    resetUserPassword() {
      
        this.$refs["resetPasswordFormRef"].validate(valid => {
          if (valid) {
            /*
             * @Date 2022/08/24 10:13:59
             * 加密密码
             */
            let encryptOldPwd = AesUtil.encrypt(this.resetPasswordForm.password, this.Secret, this.encryptIv);
            let encryptNewPwd = AesUtil.encrypt(this.resetPasswordForm.newPassword, this.Secret, this.encryptIv);

            let params = {
              userName: this.resetPasswordForm.userName,
              password: encryptOldPwd,
              newPassword: encryptNewPwd,
              code: this.resetPasswordForm.code
            }

            resetUserPassword(params).then(res => {
              if (res.code === "000000") {
                this.$message({ message: "修改密码成功！", type: "success" });
                setTimeout(() => {
                  this.resetUserPasswordVisible = false;
                  this.userDetailDialogVisible = false;
                  this.logoutSystem(false);
                }, 3000);
              } else {
                this.$message.error(res.data);
              }
            });
          }
        });
      
    },
    closeResetUserPasswordDialog() {
      this.resetUserPasswordVisible = false;
    },

    /**
     * @Author KR0132
     * @Date 2022/02/07 17:22:58
     * @Description 查询用户信息
     */
    getCurrentUserInfo() {
      getUserInfo().then(res => {
        if (res.code === "000000") {
          this.userInfo = res.data;
        }
      });
    },

    /**
     * @Author KR0132
     * @Date 2022/02/07 16:48:59
     * @Description 更新用户信息
     */
    updateUser() {
      this.$nextTick(() => {
        getBureauList().then(res => {
          if (res.code === "000000") {
            this.bureauList = res.data;
          }
        });
      });
      this.updateUserDialogVisible = true;
      this.userForm = JSON.parse(JSON.stringify(this.userInfo));
    },

    /**
     * @Author KR0132
     * @Date 2022/02/07 17:16:37
     * @Description 提交更新用户表单
     */
    commitUpdateUser() {
      this.$refs["userFormRef"].validate(valid => {
        if (valid) {
          updateUserInfo(this.userForm).then(res => {
            if (res.code === "000000") {
              this.$message({ message: res.data, type: "success" });
              this.getCurrentUserInfo();
              this.updateUserDialogVisible = false;
            } else {
              this.$message.error(res.data);
            }
          });
        }
      });
    },

    /**
     * @Author KR0132
     * @Date 2022/02/08 15:27:04
     * @Description 修改密码
     */
    resetPassword() {
      this.resetUserPasswordVisible = true;
      this.getImgCode();
    },
    
    beforeUpload(file) {
      console.log("上传之前调用", file);
      const fileList = ['pdf','doc','docx']
      var fileType = file.name.substring(file.name.lastIndexOf(".") + 1);
      if (fileList.indexOf(fileType) == -1) {
        this.$message.warning("方案上传只支持pdf、doc、docx类型文件");
        return false;
      }
      if(file.size > 52428800) {
        this.$message.error("上传失败! 文件大小不能大于50MB!");
        return false
      }
    },

    uploadProgress(event, file, fileList) {
      showLoading()
      console.log("上传时", event, file, fileList);
    },

    async uploadSuccess(response, file, fileList) {
      console.log("上传成功", response, file, fileList);
      //同名文件已经存在是否需要覆盖上传
      if(response.code=="040008"){
         this.$message.error(response.data);
      }
      try{
        if(response.code != "000000"){
          throw response.data
        }
        const res = await getAllFiles()
        this.helperFileList = res.data
        closeLoading()
        this.$message.success("上传成功");
      }catch(err){
        closeLoading()
        this.$message.error(err);
        console.log("🚀 ~ file: TopHeader.vue ~ line 395 ~ uploadSuccess ~ err", err)
      }
    },
    uploadError(err, file, fileList) {
      closeLoading()
      console.log(err, file, fileList);
      this.$message({
        type: "error",
        message: err
      });
    },
    async openHelper(){
      try{
        this.helperDialogVisible=true
        const res = await getAllFiles()
        this.helperFileList = res.data
      }catch(err){
        console.log("🚀 ~ file: TopHeader.vue ~ line 385 ~ openHelper ~ err", err)
      }
    },
    async handleDelete(row){
      try{
        await this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
        const deleteRes = await deleteFile({id:row.id})
        if(deleteRes.code != "000000"){
          throw response.meta.msg
        }
        const res = await getAllFiles()
        this.helperFileList = res.data
        this.$message.success("删除成功");
      }catch(err){
        if(err == 'cancel') return
        this.$message.error(err);
        console.log("🚀 ~ file: TopHeader.vue ~ line 432 ~ handleDelete ~ err", err)
      }
    },
    async handleDown(row){
      try{
        showLoading()
        const res = await downloadFile({id:row.id})
        closeLoading()
        this.download(row.fileName,res)
      }catch(err){
        this.$message.error(err.mesg);
        closeLoading()
        console.log("🚀 ~ file: TopHeader.vue ~ line 444 ~ handleDown ~ err", err)
      }
    },
    //  fileName : 文件名 res:二进制流
    download(fileName, res) {
      let blob = new Blob([res]);
      if (window.navigator.msSaveOrOpenBlob) {
        //兼容 IE & EDGE
        navigator.msSaveBlob(blob, fileName);
      } else {
        var link = document.createElement("a");
        // 兼容不同浏览器的URL对象
        const url = window.URL || window.webkitURL || window.moxURL;
        // 创建下载链接
        link.href = url.createObjectURL(blob);
        //命名下载名称
        link.download = fileName;
        //点击触发下载
        link.click();
        //下载完成进行释放
        url.revokeObjectURL(link.href);
      }
    },

    /*
     * @Date 2022/03/17 18:32:44
     * 显示配置城市对话框
     */
    showWeatherDialog() {
      if(!this.lineId) {
        this.$message.warning("主界面尚未选定线路，无法配置气象数据！");
        return;
      }

      this.westherConfigDialogVisible = true;
      this.listenLineForm.lineId = this.lineId;
      this.handleChangeLine();
    },
   

    /*
     * @Date 2022/03/11 16:33:38
     * 处理修改城市事件
     */
    handeRegionChange(data) {
      switch(data.type) {
        case 'province': {
          this.listenLineForm.provinceId = data.provinceId;
          getCities({provinceId: data.provinceId}).then(res => {
            this.cities = res.data;
          })
          break;
        }
        case "city": {
          this.listenLineForm.cityId = data.cityId;
          getCounties({cityId: data.cityId}).then(res => {
            this.counties = res.data;
          })
          break;
        }
        case 'county': {
          this.listenLineForm.countyId = data.countyId;

        }
      }
    },

    /*
     * @Date 2022/03/14 14:27:40
     * 处理修改气象推送配置中的线路事件
     */
    handleChangeLine() {
      this.$nextTick(() => {
        let params = {websocketUser: this.visitorId, lineId: this.listenLineForm.lineId};
        getLineWeatherPushConfig(params).then(res => {
          if(res.code==="000000") {
            getProvinces({provinceId: "0"}).then(rp => {
              this.provinces = rp.data;
            });

            if(res.data.provinceId) {
              getCities({provinceId: res.data.provinceId}).then(rcity => {
                this.cities = rcity.data;
              })
            }

            if(res.data.cityId) {
              getCounties({cityId:  res.data.cityId}).then(rcounty => {
                this.counties = rcounty.data;
              })
            }

            this.listenLineForm.provinceId = res.data.provinceId;
            this.listenLineForm.cityId = res.data.cityId;
            this.listenLineForm.countyId = res.data.countyId;


            this.$refs["cityRegionRef"].region = {provinceId: this.listenLineForm.provinceId, cityId: this.listenLineForm.cityId, countyId: this.listenLineForm.countyId}
          }
        })
      })
     
    },

     /*
     * @Date 2022/03/14 16:51:39
     * 更新气象推送配置
     */
    updateConfigWeatherMessage() {
      let params = {
        lineId: this.listenLineForm.lineId,
        isPushCityWeather: this.listenLineForm.isPushCityWeather,
        provinceId: this.listenLineForm.provinceId,
        cityId: this.listenLineForm.cityId,
        countyId: this.listenLineForm.countyId,
      }

      let region = "";
      if(this.provinces.length>0) {
        region = this.provinces.find(p => {return p.provinceId.indexOf(this.listenLineForm.provinceId)>-1});
        params.provinceName = region ? region.provinceName : "";
      }

      if(this.cities.length>0) {
         region = this.cities.find(c => {return this.listenLineForm.cityId.indexOf(c.cityId)>-1});
         params.cityName = region ? region.cityName : "";
      }

      if(this.counties.length>0) {
        region = this.counties.find(c => {return this.listenLineForm.countyId.indexOf(c.countyId)>-1});
        params.countyName = region ? region.countyName : "";
      }

      if(!params.provinceId) {
        this.$alert('必须定位当前城市所在位置！', '配置失败', {
          confirmButtonText: '确定',
          callback: action => {
            this.$message({
              type: 'warning',
              message: `取消更新推送配置！`
            });
          }
        });
        return;
      }

      if(params.provinceName.indexOf("市")==-1 && params.cityName=="" && params.countyName=="") {
        this.$alert('获取城市的天气数据必须精确到市或区的行政区域！', '配置失败', {
          confirmButtonText: '确定',
          callback: action => {
            this.$message({
              type: 'warning',
              message: `取消更新推送配置！`
            });
          }
        });
        return;
      }
      
      let locateCity = !params.countyName ? !params.cityName ? params.provinceName : params.cityName : params.countyName; 
      params.websocketUser = this.visitorId;
      params.locateCity = locateCity;

      updateSubscribeCity(params).then(res => {
         if(res.code==="000000") {

          this.$store.commit("updateCity", locateCity)

           this.westherConfigDialogVisible = false;
           this.$message.success(res.data);
         }else {
           this.$message.warning(res.data);
         }
      });

    }

   
  },
  mounted() {
    //o1d217 分支跟公网 dev 冲突，先注释old217代码
    // if (this.userMessage.userName == "") {
    //   var userInfo = JSON.parse(localStorage.getItem("userInfo"));
    //   this.$store.commit("saveUserMessage", userInfo.userName);
    // }
    // if (localStorage.getItem("userTheme")) {
    //   document.body.className = localStorage.getItem("userTheme");
    //   this.themeName = localStorage.getItem("userTheme");
    // } else {
    //   document.body.className = "green";
    //   this.themeName = "green";
    // }
    //20211023 注释
    // document.body.className = localStorage.getItem("userTheme") || "green";

    // if (!this.userMessage.userName&&localStorage.getItem("userInfo")) {
    //   var userInfo = JSON.parse(localStorage.getItem("userInfo"));
    //   this.$store.commit("saveUserMessage", userInfo.userName);
    // }
    if (this.userMessage.userName == "") {
      var userInfo = JSON.parse(localStorage.getItem("userInfo"));
      this.$store.commit("saveUserMessage", userInfo.userName);
    }
    if (localStorage.getItem("userTheme")) {
      document.body.className = localStorage.getItem("userTheme");
      this.themeName = localStorage.getItem("userTheme");
    } else {
      document.body.className = "green";
      this.themeName = "green";
    }

    /*
     * @Date 2022/03/11 08:41:10
     * 查询线路列表
     */
    getLineNames().then(res => {
      this.lineList = res.data;
    })
  }
};
</script>
<style lang="scss" scoped>
/* .weather-dialog .el-dialog {
  width: 50vw !important;
} */
/* .showWeatherDrawer {
  background-color: #17312e !important;
} */
.edit-linecity-dialog {
  .el-form-item__label {
    width: 5vw !important;
  }
  .el-form-item {
    display: flex;
  }
  .el-form-item__content {
    margin-left: 0 !important;
  }
}
</style>
<style lang="scss" scoped>

header {
  display: flex;
  justify-content: space-between;
  color: #fff;
  .left {
    width: 33.33%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .more-icon {
      margin-left: 1vw;
    }
    .weather {
      margin-left: 1.5vw;
      // margin-top: 1vh;
      display: flex;
      align-items: center;
      .weather-item {
        display: flex;
        .icon-img {
          display: block;
          width: 0.6vw;
          height: 0.6vw;
          background: url("~@/assets/imgs/green/green-air.png") no-repeat;
          background-size: 100% 100%;
          background-position: center;
        }
        .weather-font {
          font-size: 1vh;
          margin-left: .2vw;
        }
        .line {
          margin: 0 0.5vh;
          height: 1vh;
          border: 0.1vh solid rgb(109, 109, 109);
          border-top: 0.2vh solid #fff;
          border-bottom: 0.2vh solid #fff;
        }
      }
    }
  }
  .middle {
    width: 33.33%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .right {
    width: 33.33%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .help-btn{
      margin-right: 15px;
      .help-btn-div{
        display: flex;
        align-items: center;
        .mark{
          // padding: 0 10px;
          width: 20px;
          height: 20px;
          background-color: #fff;
          border-radius: 50%;
          margin-left: 5px;
          color: #000;
          line-height: 20px;
        }
      }
    }
    .user {
      margin-right: 2vw;
      margin-bottom: 0.5vh;
      display: flex;
      font-size: 1.3vh;
      span {
        height: 1.5vw;
        line-height: 1.5vw;
      }
      .user-icon {
        width: 1.5vw;
        height: 1.5vw;
        margin-right: 0.5vw;
      }
      .el-dropdown-link {
        cursor: pointer;
      }
    }
  }
}
.change-skin {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 2vh;
  .skin-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1vh;
    color: #fff;
    .label {
      width: 5vw;
      text-align-last: justify;
      color: #fff;
    }
  }
}
.reset-password-dialog {
  .el-input {
    position: relative;
    font-size: 14px;
    display: inline-block;
    width: 60%;
  }
}

.el-radio {
  color: #fff;
  font-weight: 500;
  cursor: pointer;
  margin-right: 30px;
}
</style>
